const setCurrentStepPah = (
  payload: number
): { type: string; payload: number } => {
  localStorage.setItem('currentStepPah', `${payload}`);

  return {
    type: 'SET_CURRENT_STEP_PAH',
    payload
  };
};

export { setCurrentStepPah };
