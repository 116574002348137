import React from 'react';

import Body from './Body';
import Eyes from './Eyes';
import Face from './Face';
import Freckles from './Freckles';
import Glasses from './Glasses';
import Hair from './Hair';
import Hearing from './Hearing';

type Props = {
  selected: boolean;
  type: string;
};

const FamilyIcon: React.FC<Props> = ({ selected, type }) => {
  switch (type) {
    case 'body':
      return <Body fill={selected ? 'white' : '#6BBCFF'} />;
    case 'eyes':
      return <Eyes fill={selected ? 'white' : '#6BBCFF'} />;
    case 'freckles':
      return <Freckles fill={selected ? 'white' : '#6BBCFF'} />;
    case 'glasses':
      return <Glasses fill={selected ? 'white' : '#6BBCFF'} />;
    case 'hair':
      return <Hair fill={selected ? 'white' : '#6BBCFF'} />;
    case 'hearing':
      return <Hearing fill={selected ? 'white' : '#6BBCFF'} />;
    case 'face':
      return <Face fill={selected ? 'white' : '#6BBCFF'} />;
    default:
      return null;
  }
};

export default FamilyIcon;
