import dynamic from 'next/dynamic';

import { TIconsType } from './constants';
import { IconProps as IconPropsType } from './Icon.model';

export type TIcon = TIconsType;
export type IconProps = IconPropsType;
export const Icon = dynamic<IconProps>(() => import('./Icon'), {
  ssr: false
});
