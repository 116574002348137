const setCharacterConfigured = (
  payload: boolean
): { type: string; payload: boolean } => {
  localStorage.setItem('characterConfigured', `${payload}`);

  return {
    type: 'SET_CHARACTER_CONFIGURED',
    payload
  };
};

export { setCharacterConfigured };
