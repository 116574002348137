import { BookStructure } from '@/services/models';

const populateBooksBuildKeys = (payload: BookStructure[]) => {
  return {
    type: 'POPULATE_BOOKS_BUILD_KEYS',
    payload
  };
};

export { populateBooksBuildKeys };
