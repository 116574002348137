import React from 'react';
import cx from 'classnames';

export type TypographyProps = {
  type?:
    | 'h1'
    | 'mh1'
    | 'h2'
    | 'h3'
    | 'b1'
    | 'b2'
    | 'b3'
    | 'b3b'
    | 'caption'
    | 'label-caps'
    | '14';
  tag?: 'p' | 'h1' | 'h2' | 'h3' | 'span' | 'a';
} & React.HTMLProps<
  | HTMLParagraphElement
  | HTMLHeadingElement
  | HTMLAnchorElement
  | HTMLSpanElement
>;

type TypographyRef = React.LegacyRef<
  Partial<
    | HTMLParagraphElement
    | HTMLHeadingElement
    | HTMLAnchorElement
    | HTMLSpanElement
  >
>;

const Typography = React.forwardRef<TypographyRef, TypographyProps>(
  ({ children, type, color = 'black', className, tag, ...props }, ref) => {
    const Component = tag || 'p';
    const isHeadline = !!type && ['h1', 'h2', 'mh1', 'h3'].includes(type);
    const isExtraBold =
      !!type && ['b3b', 'caption', 'label-caps'].includes(type);
    const textType = type === 'label-caps' ? 14 : type;

    return (
      <Component
        className={cx(
          `text-${color}`,
          {
            [`text-${textType}`]: textType,
            'font-body': !isHeadline,
            'font-heading': isHeadline,
            'font-bold': isHeadline,
            'font-extrabold': isExtraBold
          },
          className
        )}
        ref={ref as any}
        {...props}
      >
        {children}
      </Component>
    );
  }
);

Typography.displayName = 'Typography';

export default Typography;
