/* eslint-disable @typescript-eslint/no-explicit-any */
import { track } from '@/services/mixpanel.service';
import { useEffect, useState } from 'react';
import breakpoints from './breakpoints';
import { getViewport, ViewportPayload as ViewportPayloadType } from './utils';
import * as Sentry from '@sentry/nextjs';

export type ViewportPayload = ViewportPayloadType;

const mobileRE =
  /(android|bb\d+|meego).+mobile|armv7l|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series[46]0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i;

const tabletRE = /android|ipad|playbook|silk/i;

const isMobile = (opts: any) => {
  try {
    if (!opts) opts = {};
    let ua = opts.ua;
    if (!ua && typeof navigator !== 'undefined') ua = navigator.userAgent;
    if (ua && ua.headers && typeof ua.headers['user-agent'] === 'string') {
      ua = ua.headers['user-agent'];
    }
    if (typeof ua !== 'string') return false;

    let result = mobileRE.test(ua) || (!!opts.tablet && tabletRE.test(ua));

    if (
      !result &&
      opts.tablet &&
      opts.featureDetect &&
      navigator &&
      navigator.maxTouchPoints > 1 &&
      ua.indexOf('Macintosh') !== -1 &&
      ua.indexOf('Safari') !== -1
    ) {
      result = true;
    }

    return result;
  } catch (error) {
    track(
      `[useViewport - error] error while tracking if is mobile => ${error}`
    );

    Sentry.captureException(error);

    return true;
  }
};

function useViewport(): ViewportPayload {
  const [viewport, setViewport] = useState<ViewportPayload>({
    isDesktop: false,
    isMobile: false,
    key: 'mobile'
  });

  useEffect(() => {
    const mediaQuery = window.matchMedia(breakpoints.desktop);

    function handleMediaChange(mediaResult: ViewportPayloadType) {
      setViewport(getViewport(mediaResult as any));
    }

    handleMediaChange(mediaQuery as any);

    try {
      mediaQuery.addEventListener<any>('change', handleMediaChange);

      return () =>
        mediaQuery.removeEventListener<any>('change', handleMediaChange);
    } catch (error) {
      const isMobile_ = isMobile({ tablet: true });

      setViewport({
        isDesktop: !isMobile_,
        isMobile: isMobile_,
        key: isMobile_ ? 'mobile' : 'desktop'
      });
    }
  }, []);

  return viewport;
}

export { useViewport };
