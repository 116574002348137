import React from 'react';
import Typography from '@/components/shared/Typography';
import Button from '@/components/shared/Button';
import Input from '@/components/shared/Input';
import StepTitle from '../StepTitle';
import { useDispatch, useSelector } from 'react-redux';
import {
  setCurrentCharacterLastName,
  setCurrentCharacterName
} from '@/store/actions/characterPreview';
import { Character } from '@/services/models';

export type SecondStepProps = {
  setCharacterBuilderStep: (step: number) => void;
  setShowCharacterBuilderModal: React.Dispatch<React.SetStateAction<boolean>>;
};

export type GenderButtonProps = {
  onSelectGender: () => void;
  selected: boolean;
  gender: string;
};

export type StepTitleProps = any;

const NUMBER_OF_CHARACTERS_ALLOWED = 11;

const SecondStep: React.FC<SecondStepProps> = ({
  setCharacterBuilderStep,
  setShowCharacterBuilderModal
}) => {
  const dispatch = useDispatch();

  const { characterPreview } = useSelector(
    (state: { characterPreview: Character }) => state
  );

  function onChangeName(name: string) {
    // Makes the first letter uppercase
    let name_ = name
      .split(' ')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');

    if (name_.length > NUMBER_OF_CHARACTERS_ALLOWED) {
      name_ = name_.substring(0, NUMBER_OF_CHARACTERS_ALLOWED);
    }

    // Removes diacritics from name
    name_ = name_.normalize('NFD').replace(/[\u0300-\u036f]/g, '');

    dispatch(setCurrentCharacterName(name_));
  }

  function onChangeLastName(lastName: string) {
    // Makes the first letter uppercase
    let lastName_ = lastName
      .toLowerCase()
      .split(' ')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');

    if (lastName_.length > NUMBER_OF_CHARACTERS_ALLOWED) {
      lastName_ = lastName_.substring(0, NUMBER_OF_CHARACTERS_ALLOWED);
    }

    // Removes diacritics from name
    lastName_ = lastName_.normalize('NFD').replace(/[\u0300-\u036f]/g, '');

    dispatch(setCurrentCharacterLastName(lastName_));
  }

  return (
    <div className="flex flex-col items-center w-auto sm:w-auto pb-6 pt-7 px-6">
      <div>
        <StepTitle
          step={2}
          setCharacterBuilderStep={setCharacterBuilderStep}
          setShowCharacterBuilderModal={setShowCharacterBuilderModal}
        />
      </div>
      <div className="mt-8">
        <Typography type="b2">Child{'’'}s First Name:</Typography>
      </div>
      <div className="mt-15">
        <Input
          className="font-extrabold"
          textSize="14"
          onChange={e => onChangeName(e.target.value)}
          value={characterPreview?.name ? characterPreview.name : ''}
          style={{
            border: '1px solid #6BBCFF'
          }}
        />
      </div>
      <div className="mt-0">
        <Typography type="b2" style={{ fontSize: 14, color: 'gray' }}>
          {11 - (characterPreview?.name ? characterPreview.name.length : 0)}{' '}
          characters remaining
        </Typography>
      </div>
      <div className="mt-8">
        <Typography type="b2">Last Name (optional):</Typography>
      </div>

      <div className="mt-15">
        <Input
          className="font-extrabold"
          textSize="14"
          onChange={e => onChangeLastName(e.target.value)}
          value={characterPreview?.lastName ? characterPreview.lastName : ''}
          style={{
            border: '1px solid #6BBCFF'
          }}
        />
      </div>
      {characterPreview && typeof characterPreview.lastName !== 'undefined' && (
        <div className="mt-0">
          <Typography type="b2" style={{ fontSize: 14, color: 'gray' }}>
            {11 -
              (characterPreview?.lastName
                ? characterPreview.lastName.length
                : 0)}{' '}
            characters remaining
          </Typography>
        </div>
      )}
      <div className="mt-10">
        <Button
          className="w-81 h-14"
          onClick={() => setCharacterBuilderStep(3)}
          aria-label="Create Character!"
        >
          <span className="font-extrabold text-14 uppercase">
            Create Character!
          </span>
        </Button>
      </div>
    </div>
  );
};

export default SecondStep;
