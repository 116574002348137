import React from 'react';

type Props = {
  fill: string;
};

const Freckles: React.FC<Props> = ({ fill }) => (
  <svg
    width="24"
    height="23"
    viewBox="0 0 24 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.1214 5.48359C16.8384 5.48359 17.4196 4.90237 17.4196 4.1854C17.4196 3.46843 16.8384 2.88721 16.1214 2.88721C15.4045 2.88721 14.8232 3.46843 14.8232 4.1854C14.8232 4.90237 15.4045 5.48359 16.1214 5.48359Z"
      fill={fill}
    />
    <path
      d="M7.0062 3.27021C7.72317 3.27021 8.30439 2.68899 8.30439 1.97202C8.30439 1.25505 7.72317 0.673828 7.0062 0.673828C6.28923 0.673828 5.70801 1.25505 5.70801 1.97202C5.70801 2.68899 6.28923 3.27021 7.0062 3.27021Z"
      fill={fill}
    />
    <path
      d="M10.7388 17.5303C11.2477 17.5303 11.6602 17.1178 11.6602 16.6089C11.6602 16.1 11.2477 15.6875 10.7388 15.6875C10.2299 15.6875 9.81738 16.1 9.81738 16.6089C9.81738 17.1178 10.2299 17.5303 10.7388 17.5303Z"
      fill={fill}
    />
    <path
      d="M3.64797 19.0752C4.15685 19.0752 4.56937 18.6627 4.56937 18.1538C4.56937 17.6449 4.15685 17.2324 3.64797 17.2324C3.13909 17.2324 2.72656 17.6449 2.72656 18.1538C2.72656 18.6627 3.13909 19.0752 3.64797 19.0752Z"
      fill={fill}
    />
    <path
      d="M21.7417 8.76908C22.2506 8.76908 22.6631 8.35655 22.6631 7.84767C22.6631 7.3388 22.2506 6.92627 21.7417 6.92627C21.2328 6.92627 20.8203 7.3388 20.8203 7.84767C20.8203 8.35655 21.2328 8.76908 21.7417 8.76908Z"
      fill={fill}
    />
    <path
      d="M16.1213 13.3695C16.4887 13.3695 16.7865 13.0717 16.7865 12.7043C16.7865 12.3369 16.4887 12.0391 16.1213 12.0391C15.7539 12.0391 15.4561 12.3369 15.4561 12.7043C15.4561 13.0717 15.7539 13.3695 16.1213 13.3695Z"
      fill={fill}
    />
    <path
      d="M22.6643 14.1982C23.0317 14.1982 23.3295 13.9003 23.3295 13.5329C23.3295 13.1655 23.0317 12.8677 22.6643 12.8677C22.2969 12.8677 21.999 13.1655 21.999 13.5329C21.999 13.9003 22.2969 14.1982 22.6643 14.1982Z"
      fill={fill}
    />
    <path
      d="M5.33223 13.2543C5.69963 13.2543 5.99747 12.9565 5.99747 12.5891C5.99747 12.2217 5.69963 11.9238 5.33223 11.9238C4.96483 11.9238 4.66699 12.2217 4.66699 12.5891C4.66699 12.9565 4.96483 13.2543 5.33223 13.2543Z"
      fill={fill}
    />
    <path
      d="M11.1848 10.5561C11.7269 10.5561 12.1664 10.1166 12.1664 9.57443C12.1664 9.03228 11.7269 8.59277 11.1848 8.59277C10.6426 8.59277 10.2031 9.03228 10.2031 9.57443C10.2031 10.1166 10.6426 10.5561 11.1848 10.5561Z"
      fill={fill}
    />
    <path
      d="M12.594 22.8588C13.1361 22.8588 13.5756 22.4193 13.5756 21.8772C13.5756 21.335 13.1361 20.8955 12.594 20.8955C12.0518 20.8955 11.6123 21.335 11.6123 21.8772C11.6123 22.4193 12.0518 22.8588 12.594 22.8588Z"
      fill={fill}
    />
    <path
      d="M20.016 18.8288C20.7329 18.8288 21.3142 18.2476 21.3142 17.5306C21.3142 16.8136 20.7329 16.2324 20.016 16.2324C19.299 16.2324 18.7178 16.8136 18.7178 17.5306C18.7178 18.2476 19.299 18.8288 20.016 18.8288Z"
      fill={fill}
    />
    <path
      d="M1.85776 10.0676C2.57473 10.0676 3.15595 9.48635 3.15595 8.76938C3.15595 8.05241 2.57473 7.47119 1.85776 7.47119C1.14079 7.47119 0.55957 8.05241 0.55957 8.76938C0.55957 9.48635 1.14079 10.0676 1.85776 10.0676Z"
      fill={fill}
    />
  </svg>
);

export default Freckles;
