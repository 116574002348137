import React from 'react';

type Props = {
  fill: string;
};

const Eyes: React.FC<Props> = ({ fill }) => (
  <svg
    width="18"
    height="14"
    viewBox="0 0 18 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.5801 4.25795C14.364 0.518487 7.20607 -0.371899 3.37079 3.09054C-0.863456 6.91324 0.606064 7.9442 0.606064 7.9442C2.18291 9.97121 3.09862 10.232 3.09862 10.232C3.09862 10.232 2.4287 9.21346 2.4799 7.02949C2.54071 4.43173 5.6132 2.10749 8.93083 1.89257C12.2485 1.67764 14.3993 2.59901 16.0578 4.96449C17.7163 7.32998 17.4708 10.8933 17.4708 10.8933C17.4708 10.8933 18.6968 7.82976 16.5801 4.25795Z"
      fill={fill}
    />
    <path
      d="M15.6887 9.11198C16.0271 6.53115 15.3502 4.89263 14.0809 3.84824C12.4309 2.49056 10.2513 2.41462 8.34662 3.55209C6.37133 4.73134 5.73547 7.66841 6.65706 9.84953C7.26906 11.2979 8.23308 12.0007 8.81154 12.3098C7.65082 12.1068 6.50371 11.8322 5.37695 11.4874C5.37695 11.4874 7.20994 13.3209 10.8963 13.2901C14.5827 13.2593 15.7807 12.2149 16.3335 11.6929C16.3335 11.6929 15.6315 12.0639 14.3028 12.3275C15.1128 11.7799 15.4095 11.2438 15.6887 9.11198Z"
      fill={fill}
    />
  </svg>
);

export default Eyes;
