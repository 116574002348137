import { track } from '../mixpanel.service';
import { Product, CartItem, Cart, ShippingAddress } from '../models';
import * as Sentry from '@sentry/nextjs';

const parseShopifyProduct = (shopifyProduct: any): Product => {
  return {
    id: shopifyProduct.id,
    variants: shopifyProduct.variants.map((variant: any) => ({
      id: variant.id,
      price: variant.price
    })),
    handle: shopifyProduct.handle,
    title: shopifyProduct.title,
    description: shopifyProduct.description,
    images: shopifyProduct.images.map((i: any) => i.src),
    price: parseFloat(shopifyProduct.variants[0].price.amount),
    available: shopifyProduct.variants[0].available,
    availableForSale: shopifyProduct.availableForSale
  };
};

const parseShopifyLineItem = (shopifyLineItem: any): CartItem => {
  console.log({ shopifyLineItem });

  return {
    id: shopifyLineItem.id,
    variantId: shopifyLineItem.variant.id,
    quantity: shopifyLineItem.quantity,
    price: shopifyLineItem.variant.price.amount,
    customAttributes: shopifyLineItem.customAttributes
  };
};

const parseShopifyCart = (shopifyCart: any): Cart => {
  console.log({ shopifyCart });

  try {
    const shopifyAddress = shopifyCart.shippingAddress;
    const address: ShippingAddress = shopifyAddress && {
      firstName: shopifyAddress.firstName,
      lastName: shopifyAddress.lastName,
      company: shopifyAddress.company,
      phone: shopifyAddress.phone,
      address1: shopifyAddress.address1,
      address2: shopifyAddress.address2,
      city: shopifyAddress.city,
      country: shopifyAddress.country,
      countryCode: shopifyAddress.countryCode,
      province: shopifyAddress.province,
      provinceCode: shopifyAddress.provinceCode,
      zip: shopifyAddress.zip
    };

    console.log('shopifyCart:', shopifyCart);

    return {
      id: shopifyCart.id,
      webUrl: shopifyCart.webUrl,
      totalPrice: parseFloat(shopifyCart.totalPrice.amount),
      shippingAddress: address,
      discount: shopifyCart.discountApplications.map((d: any) => d.code),
      cartItems: shopifyCart.lineItems.map((shopifyLineItem: any) =>
        parseShopifyLineItem(shopifyLineItem)
      )
    };
  } catch (error) {
    console.log('error:', error);

    track(`[storefront.parsers] Error parsing cart => ${error}`);

    Sentry.captureException(error);

    throw new Error('Unable to parse cart');
  }
};
export { parseShopifyProduct, parseShopifyLineItem, parseShopifyCart };
