import * as React from 'react';

function UploadIcon(props: any) {
  return (
    <svg
      width={22}
      height={20}
      viewBox="0 0 22 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8 4.804h1.98v9.062c0 .118.096.214.214.214h1.607a.215.215 0 00.214-.214V4.804H14c.18 0 .279-.206.169-.345l-3-3.799a.213.213 0 00-.338 0l-3 3.796a.215.215 0 00.17.348zm12.804 8.258h-1.607a.215.215 0 00-.215.214v4.125H3.018v-4.125a.215.215 0 00-.214-.214H1.197a.215.215 0 00-.215.214v5.304c0 .474.383.857.858.857H20.16a.856.856 0 00.857-.857v-5.304a.215.215 0 00-.214-.214z"
        fill={props.color}
      />
    </svg>
  );
}

export default UploadIcon;
