import React, { useEffect, useState } from 'react';
import cx from 'classnames';

import { Icon } from '@/components/shared/Icon';
import Logo from '@/components/shared/Logo';
import Button, { RoundButton } from '@/components/shared/Button';
import Typography from '@/components/shared/Typography';
import LogoFull from '@/components/shared/LogoFull';
import Link from 'next/link';

import { useModal } from '@/hooks/useModal';
import PreviewIcon from '@/components/PreviewIcon';
import { RootStateOrAny, useSelector } from 'react-redux';

type LinkMenuProps = {
  href: string;
  selected?: boolean;
};

const LinkMenuDesktop: React.FC<LinkMenuProps> = ({
  children,
  selected = false,
  ...props
}) => {
  return (
    <Link href={props.href}>
      <div
        style={{ fontFamily: 'Nunito', fontWeight: 800, fontSize: 14 }}
        className={cx('uppercase cursor-pointer', { 'text-primary': selected })}
      >
        {children}
      </div>
    </Link>
  );
};

const LinkMenu: React.FC<LinkMenuProps> = ({ children, ...props }) => (
  <Link href={props.href}>
    {/* <Typography className="text-green" type="h3"> */}
    {children}
    {/* </Typography> */}
  </Link>
);

LinkMenu.displayName = 'link-menu';

const linkList = [
  {
    key: 'shop',
    href: '/shop',
    label: 'Buy Now'
  },
  {
    key: 'about-us',
    href: '/about-us',
    label: 'Our Mission'
  },
  {
    key: 'faq',
    href: '/faq',
    label: 'FAQ'
  },
  {
    key: 'resources',
    href: '/resources',
    label: 'Free Resources'
  },
  {
    key: 'privacy-policy',
    href: '/privacy-policy',
    label: 'Privacy Policy'
  },
  {
    key: 'terms-of-service',
    href: '/terms-of-service',
    label: 'Terms of Service'
  },
  {
    key: 'refund-policy',
    href: '/refund-policy',
    label: 'Return / Refund Policy'
  },
  {
    key: 'contact',
    href: '/faq#contact-us',
    label: 'Contact Us'
  }
];

type MenuProp = {
  className?: string;
  onClose: () => void;
};

const Menu: React.FC<MenuProp> = ({ className, onClose }) => {
  return (
    <div
      id="top"
      className={cx(
        'w-full h-full bg-white z-10 top-0 left-0 block',
        className
      )}
    >
      <div className="p-6">
        <div className="flex">
          <RoundButton color="white" onClick={onClose} aria-label="close">
            <Icon icon="close" color="green" />
          </RoundButton>
        </div>

        <div className="flex flex-col items-center gap-6 py-10">
          {linkList.map(item => (
            <LinkMenu key={item.key} href={item.href}>
              <div
                onClick={onClose}
                style={{
                  color: '#6CB9D7',
                  fontFamily: 'Libre Baskerville',
                  fontSize: 20,
                  fontStyle: 'normal',
                  fontWeight: 700,
                  letterSpacing: 0,
                  textAlign: 'center'
                }}
              >
                {item.label}
              </div>
            </LinkMenu>
          ))}
        </div>
        <LogoFull className="mx-auto" height="86px" />
      </div>
    </div>
  );
};

export type HeaderProps = {
  className?: string;
  hideSmileButton?: boolean;
  currentPage?: string;
  setShowCharacterBuilderModal: React.Dispatch<React.SetStateAction<boolean>>;
  setRedirect: React.Dispatch<React.SetStateAction<string>>;
};

const Header: React.FC<HeaderProps> = ({
  currentPage,
  className,
  setShowCharacterBuilderModal,
  setRedirect
}) => {
  const { characterConfigured, characterPreview, cartItems, productList } =
    useSelector((state: RootStateOrAny) => {
      const cartItems = state.productCart ? state.productCart.cartItems : [];
      return {
        characterConfigured: state.characterConfigured,
        characterPreview: state.characterPreview,
        cartItems,
        productList: state.productList
      };
    });

  const [name, setName] = useState<string>('');

  const { isOpen, handleClose, handleOpen } = useModal(false);

  useEffect(() => {
    if (characterPreview && characterPreview.name) {
      const processedName =
        characterPreview.name.length <= 15
          ? characterPreview.name
          : `${characterPreview.name.substring(0, 12)}...`;

      setName(processedName);
    } else {
      setName('');
    }
  }, [characterPreview]);

  const trackGetStarted = () => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const ReactPixel = window.ReactPixel;
    ReactPixel.trackCustom('CustomGetStarted');

    const gtag = (window as any).gtag;
    gtag('event', 'CustomGetStarted', {
      event_category: 'CustomGetStarted',
      event_label: 'CustomGetStarted'
    });
  };

  return (
    <>
      <Menu
        onClose={handleClose}
        className={cx({ hidden: !isOpen, fixed: isOpen }, 'lg:hidden')}
      />
      <div className={cx('bg-white shadow-md relative', className)}>
        <div className="flex justify-between items-center pt-5 pb-6 px-4 max-w-7xl mx-auto">
          <RoundButton
            color="white"
            shadowLess
            onClick={handleOpen}
            className="inline-flex lg:hidden"
            aria-label="menu"
          >
            <Icon icon="menu" size="sm" color="green" />
          </RoundButton>
          <div className="hidden lg:flex items-center gap-12 z-50">
            <LinkMenuDesktop href="/shop" selected={currentPage === 'shop'}>
              Buy Now&nbsp; {/* Adjustment for some iOSs */}
            </LinkMenuDesktop>

            <LinkMenuDesktop
              href="/about-us"
              selected={currentPage === 'about-us'}
            >
              Our Mission&nbsp;
            </LinkMenuDesktop>

            <LinkMenuDesktop href="/faq" selected={currentPage === 'faq'}>
              FAQ&nbsp;
            </LinkMenuDesktop>

            <LinkMenuDesktop
              href="/resources"
              selected={currentPage === 'resources'}
            >
              Free Resources&nbsp;
            </LinkMenuDesktop>
          </div>
          <div className="xl:absolute xl:mx-auto xl:w-1264">
            <Link href={'/'}>
              <Logo />
            </Link>
          </div>
          <div className="hidden lg:flex items-center gap-11 z-50">
            <Link href="/cart">
              <div
                className="bg-white w-10 h-10 rounded-full flex items-center justify-center cursor-pointer relative"
                style={{ boxShadow: '0px 1px 40px rgba(0, 0, 0, 0.12)' }}
              >
                {cartItems &&
                cartItems.length &&
                productList &&
                productList.length ? (
                  <div className="absolute bg-primary w-2 h-2 rounded-full top-0 right-0" /> // eslint-disable-next-line indent
                ) : null}
                <img src="/images/icons/cart.svg" alt="cart" />
              </div>
            </Link>
            <div
              onClick={() => {
                setRedirect('/shop');
                setShowCharacterBuilderModal(true);
                trackGetStarted();
              }}
            >
              {!characterConfigured || !characterPreview?.name ? (
                <Button className="w-48 h-10" aria-label="Get Started">
                  <div
                    style={{
                      fontFamily: 'Nunito',
                      fontWeight: 800,
                      fontSize: 14
                    }}
                    className="text-center"
                  >
                    Create Character
                  </div>
                </Button>
              ) : (
                <Button
                  className="inline-flex w-auto h-10"
                  aria-label="Preview book"
                >
                  <div className="w-full">
                    <div
                      className="float-left pt-1 pr-2"
                      style={{
                        fontFamily: 'Nunito',
                        fontWeight: 800,
                        fontSize: 14
                      }}
                    >
                      {name}
                    </div>
                    <PreviewIcon />
                  </div>
                </Button>
              )}
            </div>
          </div>
          <div className="inline-flex lg:hidden">
            <div className="flex">
              <Link href="/cart">
                <div
                  className="bg-white w-10 h-10 rounded-full flex items-center justify-center cursor-pointer relative"
                  style={{ boxShadow: '0px 1px 40px rgba(0, 0, 0, 0.12)' }}
                >
                  {cartItems && cartItems.length ? (
                    <div className="absolute bg-primary w-2 h-2 rounded-full top-0 right-0" />
                  ) : null}
                  <img src="/images/icons/cart.svg" alt="cart" />
                </div>
              </Link>
              {characterConfigured && (
                <div
                  className="w-10 h-10 rounded-full bg-white flex items-center justify-center border-2 border-primary ml-2"
                  onClick={() => {
                    setRedirect('/shop');
                    setShowCharacterBuilderModal(true);
                  }}
                >
                  <PreviewIcon />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
