import { processCharactersAssetsURLs } from '@/services/characterProcessor.service';
import React, { useEffect, useState } from 'react';
import { RootStateOrAny, useSelector } from 'react-redux';

const PreviewIcon: React.FC = () => {
  const [urls, setUrls] = useState<string[]>();

  const { section, currentSelection } = useSelector(
    (state: RootStateOrAny) => ({
      currentSelection: state.characterPreview?.currentSelection,
      section: state.characterPreviewBookKey?.cover?.characters[0]
    })
  );

  useEffect(() => {
    if (currentSelection && section) {
      const urls_ = processCharactersAssetsURLs(currentSelection, section);
      setUrls(urls_);
    }
  }, [section, currentSelection]);

  return (
    <div className="w-6 h-6 bg-white rounded-full overflow-hidden float-right">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 700 700"
        preserveAspectRatio="none"
      >
        {urls &&
          urls.map((url, idx) => {
            if (url.indexOf('none') === -1) {
              return (
                <image
                  key={`previewIcon-${idx}`}
                  href={url}
                  x="-240"
                  y="20"
                  height="1200"
                  width="1200"
                  onError={({ target }: any) => {
                    target.href.baseVal =
                      'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=';
                  }}
                />
              );
            }
            return null;
          })}
      </svg>
    </div>
  );
};

export default PreviewIcon;
