import React from 'react';
import cx from 'classnames';

export type InputProps = {
  placeholderColor?: 'grey';
  placeholder?: string;
  textSize?: string;
} & React.InputHTMLAttributes<HTMLInputElement>;

const Input: React.FC<InputProps> = ({
  placeholderColor = 'grey',
  placeholder,
  textSize = 'b3',
  className,
  ...props
}) => {
  return (
    <input
      className={cx(
        'border rounded-lg w-62 h-11 box-border px-5 font-sans focus:border-primary',
        `placeholder-${placeholderColor}`,
        `text-${textSize}`,
        className
      )}
      placeholder={placeholder}
      {...props}
    />
  );
};

Input.defaultProps = {
  placeholderColor: 'grey',
  textSize: 'b3'
} as InputProps;

export default Input;
