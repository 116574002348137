import Client from 'shopify-buy';
import { Cart, Product, CartItem } from '../models';
import storeFrontParams from '../../config/shopify';
import { parseShopifyProduct, parseShopifyCart } from './storefront.parsers';
import findService from '../find.service';
import { track } from '../mixpanel.service';
import { toast } from 'react-toastify';
import * as Sentry from '@sentry/nextjs';

const client = Client.buildClient({
  domain: storeFrontParams.SHOPIFY_BASE_URL || '',
  storefrontAccessToken: storeFrontParams.SHOPIFY_ACCESS_TOKEN || ''
});

const hasErrors = (payload: any) => {
  return payload.userErrors && payload.userErrors.length;
};

const toastMessage = (message: string) => {
  toast(message, {
    position: 'top-right',
    autoClose: 20000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    type: 'error'
  });
};

const processFailure = async (obj: {
  method: any;
  message: string;
  defaultResult?: any;
  id?: any;
  content?: any;
}) => {
  const { method, message, defaultResult, id, content } = obj;

  const toastMessage_ =
    'Bummer! We ran into a technical issue, and are' +
    ' working to fix it. Consider visiting us from' +
    " another device or network. If that doesn't work, try back" +
    ' in a few hours to see if the problem has been ' +
    'solved. Thank you!';

  try {
    const payload = await method(id, content);

    if (hasErrors(payload)) {
      toastMessage(toastMessage_);
      return defaultResult;
    }

    return payload;
  } catch (error) {
    track(message);

    Sentry.captureException(error);

    toastMessage(toastMessage_);

    return defaultResult;
  }
};

const getAllProducts = async (): Promise<Product[]> => {
  try {
    let allProducts = await client.product.fetchAll();
    if (hasErrors(allProducts)) {
      allProducts = await processFailure({
        method: client.product.fetchAll,
        message:
          'Unable to connect to retrieve products from Shopify. Please try' +
          ' again later or with a different connection.',
        defaultResult: []
      });
    }

    const products = allProducts.map((shopifyProduct: any) =>
      parseShopifyProduct(shopifyProduct)
    );

    return products;
  } catch (error) {
    const allProducts = await processFailure({
      method: client.product.fetchAll,
      message:
        'Unable to connect to retrieve products from Shopify. Please try' +
        ' again later or with a different connection.',
      defaultResult: []
    });

    const products = allProducts.map((shopifyProduct: any) =>
      parseShopifyProduct(shopifyProduct)
    );

    return products;
  }
};

// Resolve with the current cart or create a new one
const getCart = async (
  cart: Cart | null,
  isRaw = false
): Promise<Cart | null> => {
  if (!cart) {
    let newCart;
    try {
      newCart = await client.checkout.create();

      if (hasErrors(newCart)) {
        newCart = await processFailure({
          method: client.checkout.create,
          message:
            'Unable to connect to create a cart in Shopify. Please try' +
            ' again later or with a different connection.',
          defaultResult: {
            id: 'error_cart',
            webUrl: 'https://keepsaketales.com',
            totalPrice: 0,
            shippingAddress: null,
            discountApplications: [],
            lineItems: []
          }
        });
      }

      if (newCart.userErrors.length) {
        throw new Error(newCart.userErrors.length);
      }
    } catch (error) {
      newCart = await processFailure({
        method: client.checkout.create,
        message:
          'Unable to connect to create a cart in Shopify. Please try' +
          ' again later or with a different connection.',
        defaultResult: {
          id: 'error_cart',
          webUrl: 'https://keepsaketales.com',
          totalPrice: 0,
          shippingAddress: null,
          discountApplications: [],
          lineItems: []
        }
      });

      // console.error('[storefront.service => getCart] error:', error);
    }

    // console.log('isRaw:', isRaw)
    cart = !isRaw ? parseShopifyCart(newCart) : newCart;
  }

  // console.log('Will return cart:', cart)
  return cart;
};

const addLineItems = (id: string, payload: any): Promise<Cart> => {
  return new Promise((resolve, reject) => {
    // ! try again, show message saying that didn't work, please try again
    client.checkout
      .addLineItems(id, payload)
      .then((updatedShopifyCart: any) => {
        if (updatedShopifyCart.userErrors.length) {
          reject(new Error(updatedShopifyCart.userErrors));
        }

        resolve(updatedShopifyCart);
      })
      .catch(async (error: Error) => {
        reject(error);
      });
  });
};

const updateLineItems = (id: string, payload: any): Promise<Cart> => {
  return new Promise((resolve, reject) => {
    // ! try again, show message saying that didn't work, please try again
    client.checkout
      .updateLineItems(id, payload)
      .then((updatedShopifyCart: any) => {
        // console.log('updatedShopifyCart:', updatedShopifyCart)

        if (updatedShopifyCart.userErrors.length) {
          reject(new Error(updatedShopifyCart.userErrors));
        }
        resolve(updatedShopifyCart);
      })
      .catch(async (error: Error) => {
        reject(error);
      });
  });
};

// Adds a product to the cart
const upsertCartItem = async (
  variantId: string,
  quantity: number,
  cart: Cart,
  payload?: any
): Promise<Cart | null> => {
  // Get current cart items
  const currentCart = await getCart(cart);
  let updatedShopifyCart = null;

  if (currentCart) {
    const cartItems: CartItem[] = currentCart.cartItems;
    const cartItem = findService(cartItems, 'variantId', variantId);

    if (cartItem) {
      const content = [
        {
          id: cartItem.id,
          quantity: quantity || cartItem.quantity
        }
      ];

      try {
        // cartItem.quantity = quantity;
        updatedShopifyCart = await updateLineItems(cart.id, content);
      } catch (error: unknown) {
        updatedShopifyCart = await processFailure({
          method: client.checkout.updateLineItems,
          message:
            'Unable to change quantity on Shopify cart. Please try' +
            ' again later or with a different connection.',
          defaultResult: undefined,
          id: cart.id,
          content
        });
      }
      // eslint-disable-next-line brace-style
    }

    // Push a new line item to the cart
    else {
      const content = [
        {
          variantId,
          quantity,
          customAttributes: payload
            ? [{ key: '_payload', value: JSON.stringify(payload) }]
            : []
        }
      ];

      try {
        updatedShopifyCart = await addLineItems(cart.id, content);
      } catch (error: unknown) {
        updatedShopifyCart = await processFailure({
          method: client.checkout.addLineItems,
          message:
            'Unable to add a new item to Shopify cart. Please try' +
            ' again later or with a different connection.',
          defaultResult: undefined,
          id: cart.id,
          content
        });
      }
    }

    if (updatedShopifyCart) {
      return (cart = parseShopifyCart(updatedShopifyCart));
    } else {
      return null;
    }
  } else {
    return null;
  }
};

const removeLineItems = (id: string, payload: any): Promise<Cart> => {
  return new Promise((resolve, reject) => {
    // ! try again, show message saying that didn't work, please try again
    // ! need to block clicking the remove button while processing the remove (i.e. prevent a double click)
    client.checkout
      .removeLineItems(id, payload)
      .then((updatedShopifyCart: any) => {
        if (updatedShopifyCart.userErrors.length) {
          reject(new Error(updatedShopifyCart.userErrors));
        }

        resolve(updatedShopifyCart);
      })
      .catch(async (error: Error) => {
        await processFailure({
          method: client.checkout.addLineItems,
          message:
            'Unable to remove an item from Shopify cart. Please try' +
            ' again later or with a different connection.',
          defaultResult: undefined,
          id,
          content: payload
        });

        reject(error);
      });
  });
};

const removeCartItem = async (
  cart: Cart,
  cartItemId: string
): Promise<Cart | undefined> => {
  const content = [cartItemId];

  try {
    const updatedShopifyCart = await removeLineItems(cart.id, content);
    return (cart = parseShopifyCart(updatedShopifyCart));
  } catch (error: unknown) {
    // const processedCart = await getCart(null);
    // if (processedCart) return processedCart;
  }
};

const shopifyCartIsValid = async (id: string): Promise<boolean> => {
  try {
    const testCart = await client.checkout.fetch(id);

    const { createdAt, completedAt } = testCart;

    if (completedAt) {
      return false;
    }

    const expirationDate = new Date(createdAt);
    expirationDate.setDate(expirationDate.getDate() + 13);
    if (expirationDate.getTime() <= new Date().getTime()) {
      return false;
    }

    return true;
  } catch (error: unknown) {
    return false;
  }
};

export {
  client,
  getAllProducts,
  getCart,
  upsertCartItem,
  removeCartItem,
  shopifyCartIsValid
};
