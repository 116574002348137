import axios from 'axios';
import { track } from './mixpanel.service';
import { BookStructure } from './models';
import * as Sentry from '@sentry/nextjs';

export const getCharacterPreviewBookKey = async (): Promise<BookStructure> => {
  try {
    const { data } = await axios.get('/keys/character-preview-book-key.json');
    // console.log('data:', data)
    return data;
  } catch (error: any) {
    track(
      '[characterPreviewBookKey.service] Error while downloading characterPreviewBookKey =>',
      error
    );

    Sentry.captureException(error);

    console.log(error.response, 'error??');

    return error;
  }
};
