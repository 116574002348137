import axios from 'axios';
import { track } from './mixpanel.service';
import * as Sentry from '@sentry/nextjs';

const processHeic = async (base64: string): Promise<string> => {
  try {
    const { data } = await axios.post(
      process.env.NEXT_PUBLIC_API_BASE_URL + '/process-heic',
      { base64 }
    );
    return data;
  } catch (error: any) {
    track('[processHeic] Error while converting file to HEIC =>', error);

    console.log(error.response, 'error??');

    Sentry.captureException(error);

    throw error;
  }
};

export default processHeic;
