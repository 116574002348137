import React from 'react';
import cx from 'classnames';

import Link from 'next/link';

import Typography from '@/components/shared/Typography';
import LogoFull from '@/components/shared/LogoFull';
import { RoundButton } from '@/components/shared/Button';
import { Icon, TIcon } from '@/components/shared/Icon';

import { useViewport } from '@/hooks/useViewport';

import { LINKS } from './constants';

import styles from './styles.module.css';

const Links = React.memo(() => {
  return (
    <div className="grid grid-cols-3 gap-y-2 gap-x-8">
      {LINKS.map((link, idx) => {
        return link.label ? (
          <Link key={idx} href={link.href} passHref>
            <Typography tag="a" type="label-caps">
              {link.label}
            </Typography>
          </Link>
        ) : (
          <div key={idx} />
        );
      })}
    </div>
  );
});

Links.displayName = 'Links';

const BackToTheTop: React.FC<React.ButtonHTMLAttributes<HTMLButtonElement>> = ({
  className,
  ...props
}) => (
  <button
    className={cx(
      'bg-green-dark flex justify-center items-center w-full py-5',
      className
    )}
    type="button"
    aria-label="Back to the top"
    {...props}
  >
    <Typography color="white" type="label-caps" className="relative uppercase">
      Back to the top
      <Icon
        className="absolute ml-7 text-xs mt-1"
        icon="chevron-up"
        color="white"
      />
    </Typography>
  </button>
);

const Contacts = React.memo(() => {
  // const phoneNumber = '+1 (987) 654-3210'
  const emailContact = 'contact@mykeepsaketales.com';

  const socialNetworks: { href: string; icon: TIcon; title: string }[] = [
    {
      href: 'https://www.instagram.com/keepsaketales',
      icon: 'instagram',
      title: 'Instagram keepsaketales'
    },
    {
      href: 'https://www.facebook.com/KeepsakeTalesUSA',
      icon: 'facebook',
      title: 'Facebook keepsaketales'
    }
  ];

  return (
    <div className="flex flex-col">
      <Typography className="mb-2" type="label-caps">
        Contact info
      </Typography>
      <Typography className="mb-1" type="b3">
        {emailContact}
      </Typography>
      {/* <Typography type="b3">{phoneNumber}</Typography> */}
      <div className="flex items-center gap-2 mt-2 xl:pt-8">
        {socialNetworks.map((link, idx) => (
          <a
            key={`social-network-${idx}-${link.title}`}
            href={link.href}
            target="_blank"
            rel="noreferrer"
          >
            <Icon tag="a" size="md" color="black" icon={link.icon} />
          </a>
        ))}
      </div>
    </div>
  );
});

Contacts.displayName = 'Contacts';

const SignUp = React.memo(() => {
  const [text, setText] = React.useState('');

  return (
    <div className="max-w-xs">
      <Typography className="uppercase mb-1" type="label-caps">
        Sign up for updates
      </Typography>
      <Typography>
        Promotions, new products and sales. Directly to your inbox.
      </Typography>
      <div id="mc_embed_signup">
        <form
          action="https://gmail.us5.list-manage.com/subscribe/post?u=0d7c744b5c72c3837e7aa76e8&amp;id=a173a4bee9"
          method="post"
          id="mc-embedded-subscribe-form"
          name="mc-embedded-subscribe-form"
          className="flex items-center gap-3 mt-3"
          target="_blank"
        >
          <input
            type="email"
            onChange={e => setText(e.target.value)}
            value={text}
            name="EMAIL"
            id="mce-EMAIL"
            placeholder="email address"
            required
            className="border rounded-full w-62 h-11 box-border px-5 font-sans focus:border-primary focus:outline-none placeholder-grey text-b3"
          />
          <div style={{ position: 'absolute', left: -5000 }} aria-hidden="true">
            <input
              type="text"
              name="b_0d7c744b5c72c3837e7aa76e8_a173a4bee9"
              tabIndex={-1}
              // value=""
            />
          </div>
          <RoundButton
            type="submit"
            value="Subscribe"
            name="subscribe"
            onClick={() => {
              setTimeout(() => {
                setText('');
              }, 5000);
            }}
            aria-label="next"
          >
            <Icon icon="arrow-right" color="white" />
          </RoundButton>
        </form>
      </div>
    </div>
  );
});

SignUp.displayName = 'SignUp';

export type FooterProps = {
  classNameMobile?: string;
  classNameDesktop?: string;
};

type DesktopFooterProps = FooterProps & {
  className?: string;
};

const DesktopFooter = React.memo(({ className }: DesktopFooterProps) => {
  return (
    <div className={cx(styles.containerFooterDesktop, className)}>
      <div className="flex justify-evenly">
        <div className="flex gap-12">
          <LogoFull className="mb-5" width={129} />
          <Contacts />
        </div>

        <div className="flex items-start">
          <Links />
        </div>

        <div className="flex">
          <SignUp />
        </div>
      </div>

      <div className="text-center pb-72 mt-8">
        <Typography color="black" type="b3">
          8040 Sycamore Hill Lane Raleigh NC 27612
        </Typography>
        <Typography color="black" type="b3">
          Copyright © 2021, My Keepsake Tales. Powered by Shopify
        </Typography>
      </div>
    </div>
  );
});

DesktopFooter.displayName = 'DesktopFooter';

type MobileFooterProps = FooterProps & {
  className?: string;
};

const MobileFooter = React.memo(({ className }: MobileFooterProps) => {
  return (
    <div className={cx(styles.containerFooterMobile, className)}>
      <LogoFull className="mt-20" width={106} />

      <div className="text-center mb-36 mt-8">
        <Typography color="black" type="b3" className="max-w-xs">
          8040 Sycamore Hill Lane Raleigh NC 27612
        </Typography>
        <Typography color="black" type="b3" className="max-w-xs">
          Copyright © 2021, My Keepsake
        </Typography>
        <Typography color="black" type="b3" className="max-w-xs">
          Tales. Powered by Shopify
        </Typography>
      </div>
      <Link href="#top">
        <BackToTheTop />
      </Link>
    </div>
  );
});

MobileFooter.displayName = 'MobileFooter';

const Footer: React.FC<FooterProps> = ({
  classNameDesktop,
  classNameMobile,
  ...props
}) => {
  const { isMobile } = useViewport();

  const content = isMobile ? (
    <MobileFooter className={classNameMobile} {...props} />
  ) : (
    <DesktopFooter className={classNameDesktop} {...props} />
  );

  return content;
};

export default Footer;
