import Jimp from 'jimp';
import { track } from './mixpanel.service';
import * as Sentry from '@sentry/nextjs';

const load = async (file: Buffer): Promise<Jimp> => {
  return Jimp.read(file);
};

const resize = async (jimpFile: Jimp): Promise<Jimp> => {
  return jimpFile.resize(750, Jimp.AUTO);
};

const saveImageBuffer = (jimpFile: Jimp): Promise<Buffer> => {
  return jimpFile
    .rgba(false)
    .getBufferAsync(Jimp.MIME_JPEG)
    .then(buffer => {
      return buffer;
    })
    .catch(error => {
      track(`[jimp] Error while saving image to buffer => ${error}`);

      Sentry.captureException(error);

      throw new Error(error);
    });
};

export { load, resize, saveImageBuffer };
