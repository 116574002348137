import {
  AssetFamily,
  AssetsUrls,
  BookStructure,
  Character,
  CharacterAssets,
  CurrentSelection,
  FamilyName,
  Section,
  TypeParams
} from './models';

const initialParams = {
  gender: 'girl' as const,
  girl: 'Olivia',
  boy: 'Liam'
};

// Returns the correct AssetFamily from the Character Build Key to be processed
const returnCharacterBuildFamily = (
  currentSelectedFamily: string,
  characterBuildKey: CharacterAssets
): AssetFamily | undefined => {
  for (let j = 0; j < characterBuildKey.length; j += 1) {
    if (currentSelectedFamily === characterBuildKey[j].familyName) {
      return characterBuildKey[j];
    }
  }
};

const getFirstValidType = (
  characterBuildFamilyTypes: {
    [key: string]: TypeParams;
  },
  gender: 'boy' | 'girl'
): string | undefined => {
  const keys = Object.keys(characterBuildFamilyTypes);
  for (let i = 0; i < keys.length; i += 1) {
    const availableGenders = characterBuildFamilyTypes[keys[i]].genders;
    for (let j = 0; j < availableGenders.length; j += 1) {
      if (availableGenders[j] === gender) {
        return keys[i];
      }
    }
  }
};

// This functions intializes a character to be stored on redux
// Gender and name are optional variables
const initializeCharacter = (
  characterBuildKey: CharacterAssets,
  gender?: 'boy' | 'girl' | null,
  name?: string
): Character => {
  // console.log('[initializeCharacter] characterBuildKey:', characterBuildKey)

  // First starts defining the gender, if nothing is defined will consume
  // what is defined on the initialParams object
  const processedGender: 'boy' | 'girl' = gender || initialParams.gender;

  // If a name was defined, will create the character with the provided
  // name, otherwise, will consume the default name specified on the
  // initialParams object, according to the specified gender
  const processedName = name || initialParams[processedGender];

  // Creates an empty character object to be processed
  const character: Character = {
    name: null,
    lastName: null,
    gender: null,
    currentSelection: {
      hair: {
        type: null,
        color: null
      },
      body: {
        type: null,
        color: null
      },
      face: {
        type: null,
        color: null
      },
      eyes: {
        type: null,
        color: null
      },
      freckles: {
        type: null,
        color: null
      },
      glasses: {
        type: null,
        color: null
      },
      hearing: {
        type: null,
        color: null
      }
    }
  };

  // Fills name and gender in the character
  character.name = processedName;
  character.lastName = '';
  character.gender = processedGender;

  // Fills "assets on "Character"
  const familiesKeys = Object.keys(character.currentSelection);
  for (let i = 0; i < familiesKeys.length; i += 1) {
    // Navigates through all the families specified on the character object
    // Return the current family name to be processed
    const currentSelectedFamily = familiesKeys[i];

    // Returns the correct AssetFamily from the Character Build Key to be processed
    const assetFamily = returnCharacterBuildFamily(
      currentSelectedFamily,
      characterBuildKey
    );

    // Returns the first available "type" from a family from the character build key
    // filtering by gender
    const characterBuildFamilyTypeKey =
      assetFamily?.types &&
      (assetFamily?.mandatory || currentSelectedFamily === 'hair')
        ? getFirstValidType(assetFamily?.types, character.gender)
        : undefined;

    // Returns the first available "color" from a family from the character build key
    const colorSelected: number =
      assetFamily?.colors &&
      currentSelectedFamily &&
      (currentSelectedFamily === 'face' || currentSelectedFamily === 'body')
        ? Object.keys(assetFamily.colors).length - 1
        : 0;

    const characterBuildFamilyColorKey = assetFamily?.colors
      ? Object.keys(assetFamily?.colors)[colorSelected]
      : undefined;

    // Fills the new character with the processed type and color parameters
    character.currentSelection[currentSelectedFamily] = {
      type: characterBuildFamilyTypeKey || 'none',
      color: characterBuildFamilyColorKey || null
    };
  }

  // console.log('character:', character)
  return character;
};

const processCharactersAssetsURLs = (
  currentSelection: CurrentSelection,
  section: Section,
  bookName?: string
): string[] => {
  const r: string[] = [];

  // Navigates though all the layers specified on the book key
  // (in this case this is a key to process the character preview)
  for (let j = 0; j < section.layers.length; j += 1) {
    // console.log(section.layers[j]);

    const realAssetFamily = section.layers[j].assetFamily; // i.e. clothes
    let baseAssetFamily = section.layers[j].assetFamily; // i.e. body

    if (baseAssetFamily === 'clothes') {
      baseAssetFamily = 'body';
    }

    // Returns the layer type
    const selectedType = currentSelection[baseAssetFamily]?.type;

    if (selectedType) {
      // Processes the color variable
      let color = currentSelection[baseAssetFamily].color
        ? `-${currentSelection[baseAssetFamily].color}`
        : '';
      if (realAssetFamily === 'clothes') {
        color = '-base';
      }

      // Process the asset component, if available, i.e. hair top and bottom
      const assetComponent = section.layers[j].assetComponent
        ? `-${section.layers[j].assetComponent}`
        : '';

      // Process the orientation variable, if any
      const orientation = section.layers[j].orientation
        ? `-${section.layers[j].orientation}`
        : '';

      // Process the permutation variable, if any
      const permutation = section.layers[j].permutation
        ? `-${section.layers[j].permutation}`
        : '';

      // Builds the S3 bucket key by combining all the processed variables
      const key = `${selectedType}${color}${assetComponent}${orientation}${permutation}.png`;

      // Push the variable into an array that will be returned
      // const processedBookName = bookName || 'character-builder'
      /* r.push(
        `https://kst-books-avatar-assets.s3.amazonaws.com/${processedBookName}/${baseAssetFamily}/${key}`
      ) */

      // TODO: Add CloudfrontInstaceId to env var - kst-books-avatar-assets
      const cloudFrontBooksAvatarAssetsUrl =
        process.env.NEXT_PUBLIC_CF_BOOKS_AVATAR_ASSETS;
      r.push(
        `https://${cloudFrontBooksAvatarAssetsUrl}/preview/${realAssetFamily}/${key}`
      );
    }
  }

  return r;
};

// This function creates the array of URLs that will be used to create the character preview image
const processCharacterAssets = (
  bookStructure: BookStructure,
  character: Character
): AssetsUrls => {
  const section = bookStructure.cover.characters[0];
  return [
    // First adds the background image
    bookStructure.cover.backgroundImage,
    // Then runs a function that processed all the URLs for all the assets families
    ...processCharactersAssetsURLs(character.currentSelection, section)
  ];
};

// This function processes a character object and replaces properties when necessary
const setProperty = (payload: {
  character: Character;
  familyName: FamilyName;
  type?: string;
  color?: string;
}): Character => {
  const { character, familyName, type, color } = payload;

  if (type) character.currentSelection[familyName].type = type;
  if (color) character.currentSelection[familyName].color = color;

  if (familyName === 'body') {
    if (color) character.currentSelection.face.color = color;
  } else if (familyName === 'face') {
    if (color) character.currentSelection.body.color = color;
  }

  return character;
};

export {
  initializeCharacter,
  processCharacterAssets,
  processCharactersAssetsURLs,
  setProperty
};
