import { useViewport } from '@/hooks/useViewport';
import React, { useState } from 'react';
import Input from '@/components/shared/Input';
import Modal from 'react-modal';
import Button from '@/components/shared/Button';
import DropZone from '@/components/DropZone';
import { submitContactForm } from '@/services/AirTable';
import { track } from '@/services/mixpanel.service';
import * as Sentry from '@sentry/nextjs';
import CloseIcon from '@/components/shared/Icon/ReactIcons/CloseIcon';

type Props = {
  show: boolean;
  setShowModalBetterRepresentChild: React.Dispatch<
    React.SetStateAction<boolean>
  >;
  setShowModalConfirmation: React.Dispatch<React.SetStateAction<boolean>>;
};

const ModalBetterRepresentChild: React.FC<Props> = ({
  show,
  setShowModalBetterRepresentChild,
  setShowModalConfirmation
}) => {
  const [name, setName] = useState<string>('');

  const [email, setEmail] = useState<string>('');

  const [message, setMessage] = useState<string>('');

  const [selectedFile, setSelectedFile] = useState<string>('');

  const [selectedFileName, setSelectedFileName] = useState<string>('');

  const [uploading, setUploading] = useState<boolean>(false);

  const viewport = useViewport();

  const returnFiles = (files: File[]) => {
    const file = files[0];

    const reader = new FileReader();
    reader.onload = (theFile => {
      return (e: any) => {
        const binaryData = e.target.result;
        // Converting Binary Data to base 64
        const base64String = window.btoa(binaryData);
        setSelectedFile(base64String);
        setSelectedFileName(file.name);
      };
    })(file);
    // Read in the image file as a data URL.
    reader.readAsBinaryString(file);
  };

  const processFormSubmit = async () => {
    setUploading(true);

    try {
      await submitContactForm({
        name,
        email,
        subject: 'Character Feedback',
        message,
        fileName: selectedFileName,
        file: selectedFile
      });

      setShowModalBetterRepresentChild(false);
      setShowModalConfirmation(true);
      setUploading(false);
    } catch (error) {
      track(
        `[ModalBetterRepresentFile] Error while submitting contact form => ${error}`
      );
      Sentry.captureException(error);
      console.log('error:', error);
    }
  };

  return (
    <Modal
      isOpen={show}
      style={{
        overlay: {
          display: 'flex',
          backgroundColor: 'rgb(86 86 86 / 100%)',
          paddingTop: '5vh',
          flexDirection: 'column',
          alignItems: 'stretch',
          zIndex: 1000
        },
        content: {
          borderWidth: 0,
          overflow: 'initial',
          borderRadius: viewport.isMobile
            ? '16px 16px 0px 0px'
            : '16px 16px 16px 16px',
          width: viewport.isMobile ? '100%' : 721,
          backgroundColor: '#FCFCFC',
          margin: viewport.isDesktop ? 'auto' : undefined,
          height: viewport.isDesktop ? '80vh' : '95vh',
          overflowX: 'hidden',
          overflowY: 'auto',
          position: 'relative',
          inset: '0'
        }
      }}
      contentLabel="modalBetterRepresentChild"
    >
      <div
        className="absolute h-10 w-10 rounded-full shadow-card border-2 border-gray-100 flex justify-center items-center cursor-pointer"
        style={{
          right: viewport.isDesktop ? 'calc(50% - 342px)' : 20,
          top: 16
        }}
        onClick={() => setShowModalBetterRepresentChild(false)}
      >
        <CloseIcon />
      </div>
      <h1
        className="w-full text-center pt-8 px-16"
        style={{
          fontSize: 32,
          fontFamily: 'Libre Baskerville',
          fontWeight: 700
        }}
      >
        How could we better represent your child?
      </h1>
      <p
        className="pt-6 text-center px-20"
        style={{ fontFamily: 'Nunito', fontSize: 16, fontWeight: 400 }}
      >
        Our mission is to show every child that they are one of a kind. Please
        include feedback below to help us better represent your child.{' '}
      </p>
      <div className="px-10">
        <Input
          placeholder="Name (optional)"
          className="w-full border-grey mt-10"
          onChange={e => setName(e.target.value)}
          value={name}
        />
        <Input
          placeholder="Email (optional)"
          className="w-full border-grey mt-6"
          onChange={e => setEmail(e.target.value)}
          value={email}
        />
        <textarea
          rows={4}
          name="message"
          className="w-full mt-6 placeholder-grey border-grey border rounded-lg p-2 focus:border-primary"
          placeholder="Message"
          onChange={e => setMessage(e.target.value)}
          value={message}
        />
        <DropZone selectedFile={selectedFileName} returnFiles={returnFiles} />
      </div>
      <Button
        className="mt-4 float-right mr-10"
        onClick={processFormSubmit}
        aria-label="SEND FEEDBACK"
      >
        <div className="flex px-4">
          {uploading && (
            <svg
              className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                className="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                strokeWidth="4"
                data-darkreader-inline-stroke=""
                // style="--darkreader-inline-stroke:currentColor;"
              ></circle>
              <path
                className="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                data-darkreader-inline-fill=""
                // style="--darkreader-inline-fill:currentColor;"
              ></path>
            </svg>
          )}
          <span>SEND FEEDBACK</span>
        </div>
      </Button>
    </Modal>
  );
};

export default ModalBetterRepresentChild;
