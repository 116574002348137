import React, { useState } from 'react';
import cx from 'classnames';
import Button from '@/components/shared/Button';
import IllustrationPreview from '@/components/shared/IllustrationPreview';
import TabbedAvatar from '@/components/shared/TabbedAvatar';
import StepTitle from '../StepTitle';
import { useDispatch, useSelector } from 'react-redux';
import { initializeCharacter } from '@/services/characterProcessor.service';
import { updateCharacter } from '@/store/actions/characterPreview';
import { Cart, CharacterAssets, FamilyName } from '@/services/models';
import { setCharacterConfigured } from '@/store/actions/characterConfigured';
import router from 'next/router';
import ModalBetterRepresentChild from './ModalBetterRepresentChild';
import ModalConfirmation from './ModalConfirmation';

export type GenderButtonProps = {
  onSelectGender: () => void;
  selected: boolean;
  gender: string;
};

type Props = {
  setCharacterBuilderStep: (step: number) => void;
  setShowCharacterBuilderModal: React.Dispatch<React.SetStateAction<boolean>>;
  setShowPahModal?: React.Dispatch<React.SetStateAction<boolean>>;
  redirect?: string;
};

const ThirdStep: React.FC<Props> = ({
  setCharacterBuilderStep,
  setShowCharacterBuilderModal,
  setShowPahModal,
  redirect
}) => {
  const dispatch = useDispatch();

  const { configSetupBuildKey, productCart } = useSelector(
    (state: { configSetupBuildKey: CharacterAssets; productCart: Cart }) =>
      state
  );

  const [selectedFamily, setSelectedFamily] = useState<FamilyName>('body');

  const [showModalBetterRepresentChild, setShowModalBetterRepresentChild] =
    useState<boolean>(false);

  const [showModalConfirmation, setShowModalConfirmation] =
    useState<boolean>(false);

  const trackApproveMainCharacter = () => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const ReactPixel = window.ReactPixel;
    ReactPixel.trackCustom('CustomApproveMainCharacter');

    const gtag = (window as any).gtag;
    gtag('event', 'CustomApproveMainCharacter', {
      event_category: 'CustomApproveMainCharacter',
      event_label: 'CustomApproveMainCharacter'
    });
  };

  return (
    <>
      <div className="flex flex-col items-center w-auto xl:max-w-931 pb-6 pt-7 px-6">
        <div>
          <StepTitle
            step={3}
            setCharacterBuilderStep={setCharacterBuilderStep}
            setShowCharacterBuilderModal={setShowCharacterBuilderModal}
          />
        </div>
        <div className="w-81 xl:flex xl:flex-row xl:w-auto xl:h-96">
          <div>
            <IllustrationPreview
              selectedFamily={selectedFamily}
              setSelectedFamily={setSelectedFamily}
            />
          </div>
          <div className="xl:flex xl:flex-col xl:max-w-264 xl:h-80">
            <div className="text-center xl:text-left pt-9 pb-4">
              <p className="font-extrabold text-14 uppercase">
                {`SELECTING ${selectedFamily}`}
              </p>
            </div>
            <div className="h-auto w-full">
              <TabbedAvatar selectedFamily={selectedFamily} />
            </div>
          </div>
        </div>
        {productCart?.cartItems && productCart.cartItems.length > 0 && (
          <div
            className="xl:mt-36 flex flex-col xl:flex-row"
            style={{
              fontFamily: 'Nunito',
              fontWeight: 400,
              fontSize: 16,
              color: '#FC6780'
            }}
          >
            {'*The changes will not affect books that are already in the cart.'}
          </div>
        )}
        <div
          className={cx('flex flex-col xl:flex-row', {
            'xl:mt-4': productCart?.cartItems.length,
            'xl:mt-36': !productCart?.cartItems.length
          })}
        >
          <div className="flex items-center justify-center mb-4 xl:mb-0 xl:ml-2 mt-4 xl:mt-0 xl:mr-2">
            <Button
              className="w-81 h-14 border lg:w-72 border-primary"
              color="secondary"
              onClick={() => {
                dispatch(setCharacterConfigured(false));

                // Generate new empty character
                const newCharacter = initializeCharacter(configSetupBuildKey);
                dispatch(updateCharacter(newCharacter));

                setCharacterBuilderStep(1);
              }}
              aria-label="START ALL OVER"
            >
              <span className="font-extrabold text-14 text-primary">
                START ALL OVER
              </span>
            </Button>
          </div>
          <div className="flex items-center justify-center">
            <Button
              className="w-81 lg:w-72 h-14"
              onClick={() => {
                dispatch(setCharacterConfigured(true));

                setShowCharacterBuilderModal(false);

                trackApproveMainCharacter();

                if (redirect) {
                  if (redirect === 'pahModal') {
                    if (setShowPahModal) {
                      setShowPahModal(true);
                    }
                  } else {
                    router.push(redirect);
                  }
                }
              }}
              aria-label="APPROVE MAIN CHARACTER"
            >
              <div className="flex justify-center items-center">
                <span className="font-extrabold text-14">
                  APPROVE MAIN CHARACTER
                </span>
              </div>
            </Button>
          </div>
        </div>
        <div
          className="pt-8 text-center"
          style={{
            fontFamily: 'Nunito',
            fontWeight: 400,
            fontSize: 18
          }}
        >
          If we can better represent your child,
        </div>
        <div
          className="text-center cursor-pointer"
          style={{
            fontFamily: 'Nunito',
            fontWeight: 800,
            fontSize: 14,
            color: '#6CB9D7'
          }}
          onClick={() => setShowModalBetterRepresentChild(true)}
        >
          PLEASE LET US KNOW
        </div>
      </div>
      <ModalBetterRepresentChild
        show={showModalBetterRepresentChild}
        setShowModalBetterRepresentChild={setShowModalBetterRepresentChild}
        setShowModalConfirmation={setShowModalConfirmation}
      />
      <ModalConfirmation
        show={showModalConfirmation}
        setShowModalConfirmation={setShowModalConfirmation}
      />
    </>
  );
};

export default ThirdStep;
