import React from 'react';

type UseModalType = {
  isOpen: boolean;
  handleOpen: () => void;
  handleClose: () => void;
  handleInverse: () => void;
};

export const useModal = (defaultValue = false): UseModalType => {
  const [isOpen, setIsOpen] = React.useState<boolean>(defaultValue);

  const handleOpen = React.useCallback(
    () => setIsOpen(true),
    [setIsOpen, isOpen]
  );

  const handleClose = React.useCallback(
    () => setIsOpen(false),
    [setIsOpen, isOpen]
  );

  const handleInverse = React.useCallback(
    () => setIsOpen(!isOpen),
    [setIsOpen, isOpen]
  );

  return {
    isOpen,
    handleOpen,
    handleClose,
    handleInverse
  };
};
