import { CharacterAssets } from '@/services/models';

// Action Creator
const loadConfigSetupBuildKey = (payload: CharacterAssets) => {
  return {
    type: 'LOAD_CONFIG_SETUP_BUILD_KEY',
    payload
  };
};

export { loadConfigSetupBuildKey };
