import axios from 'axios';
import { track } from './mixpanel.service';
import { BookStructure, PrintAtHome, Character } from './models';
import * as Sentry from '@sentry/nextjs';

const pahKeysList = [
  'room-butterfly',
  'room-cheetah',
  'room-dino',
  'room-shark',
  'room-space',
  'room-sport',
  'room-superhero',
  'room-tiedye',
  'room-unicorn',
  'room-zebra'
  // 'valentines-space',
  // 'valentines-tiedye',
  // 'valentines-superhero',
  // 'valentines-shark',
  // 'valentines-dino',
  // 'valentines-sport',
  // 'valentines-butterfly',
  // 'valentines-unicorn',
  // 'valentines-zebra',
  // 'valentines-cheetah'
];

export const loadPahKeys = async (): Promise<BookStructure[] | null> => {
  try {
    const allRequests = [];
    for (const key of pahKeysList) {
      allRequests.push(axios.get(`/keys/print-at-home/${key}.json`));
    }
    return (await Promise.all(allRequests)).map(r => r.data);
  } catch (error: any) {
    track('[printAtHome.service] Error preloading printAtHome keys =>', error);
    console.log(error.response, 'error??');
    return null;
  }
};

// const eventBodyMock: any = {
//   character: {
//     name: 'Tiago Brito',
//     gender: 'boy',
//     currentSelection: {
//       body: { type: 'basebody', color: '1' },
//       face: { type: 'ovalface', color: '1' },
//       eyes: { type: 'round', color: 'blue' },
//       hair: { type: 'bsss', color: 'darkbrown' },
//       glasses: { type: 'none', color: 'base' },
//       freckles: { type: 'none', color: 'base' },
//       hearing: { type: 'none', color: 'base' }
//     }
//   },
//   clientEmail: 'tiagoitferreira@gmail.com',
//   clientName: 'Tiago Brito',
//   assetCode: 'room-sport'
// };

export const sendDesign = async (payload: {
  printAtHome: PrintAtHome;
  character: Character;
}): Promise<any> => {
  console.log('sendDesign');

  const { printAtHome, character } = payload;

  console.log({ printAtHome, character });

  try {
    const { data } = await axios.post(
      process.env.NEXT_PUBLIC_API_BASE_URL + '/print-at-home',
      // eventBodyMock
      {
        character,
        clientName: printAtHome.name,
        clientEmail: printAtHome.email,
        assetCode: printAtHome.assetCode
      }
    );
    return data;
  } catch (error: any) {
    track(
      '[classifyCharacterImage] Error returned from Print at home  =>',
      error
    );
    console.log(error.response, 'error??');
    Sentry.captureException(error);
    throw error;
  }
};
