import * as React from 'react';

function CopyIcon(props: any) {
  return (
    <svg
      width={18}
      height={18}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#prefix__clip0)" fill="#8F8F8F">
        <path d="M14.315 14.954h.599a3.045 3.045 0 003.045-3.045V3.076A3.046 3.046 0 0014.914.03H6.07a3.046 3.046 0 00-3.046 3.046v.62a.64.64 0 101.27 0v-.62A1.787 1.787 0 016.08 1.29h8.833A1.786 1.786 0 0116.7 3.076v8.833a1.796 1.796 0 01-1.787 1.776h-.63a.64.64 0 000 1.27h.03z" />
        <path d="M3.056 18h8.812a3.045 3.045 0 003.046-3.046V6.122a3.046 3.046 0 00-3.046-3.046H3.025A3.046 3.046 0 00-.02 6.122v8.832A3.046 3.046 0 003.056 18zm8.812-13.685A1.786 1.786 0 0113.655 6.1v8.833a1.797 1.797 0 01-1.787 1.797H3.025a1.797 1.797 0 01-1.787-1.797V6.1a1.787 1.787 0 011.787-1.786h8.843z" />
      </g>
      <defs>
        <clipPath id="prefix__clip0">
          <path
            fill="#fff"
            transform="matrix(-1 0 0 1 18 0)"
            d="M0 0h18v18H0z"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export default CopyIcon;
