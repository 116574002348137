import { track } from '@/services/mixpanel.service';
import { Cart, Character, BookProps } from '@/services/models';
import {
  upsertCartItem,
  removeCartItem as removeCartItem_
} from '@/services/shopify/storefront.service';
import * as Sentry from '@sentry/nextjs';

const persist = async (payload: Cart) => {
  localStorage.setItem('productCart', JSON.stringify(payload));
};

const loadCart = (payload: Cart): { type: string; payload: Cart } => {
  persist(payload);

  return {
    type: 'LOAD_CART',
    payload
  };
};

// soft-cover:
const addCartItem = async (
  variantId: string,
  cart: Cart,
  payload: {
    bookName: string;
    character: Character;
    bookProps: BookProps;
  }
): Promise<{ type: string; payload: Cart } | void> => {
  try {
    const upToDateCart = await upsertCartItem(variantId, 1, cart, payload);

    if (upToDateCart) {
      persist(upToDateCart);

      return {
        type: 'ADD_CART_ITEM',
        payload: upToDateCart
      };
    }
  } catch (error) {
    track('[productCart - addCartItem] error => ', error);
    Sentry.captureException(error);
  }
};

const updateCartItemQuantity = async (
  variantId: string,
  quantity: number,
  cart: Cart
): Promise<{ type: string; payload: Cart } | void> => {
  try {
    const upToDateCart = await upsertCartItem(variantId, quantity, cart);
    if (upToDateCart) {
      persist(upToDateCart);

      return {
        type: 'UPDATE_CART_ITEM_QUANTITY',
        payload: upToDateCart
      };
    }

    return;
  } catch (error) {
    track('[productCart - updateCartItemQuantity] error => ', error);
    Sentry.captureException(error);
  }
};

const removeCartItem = async (
  cart: Cart,
  cartItemId: string
): Promise<{
  type: string;
  payload: Cart;
} | void> => {
  try {
    const upToDateCart: Cart | undefined = await removeCartItem_(
      cart,
      cartItemId
    );
    if (upToDateCart) {
      persist(upToDateCart);

      return {
        type: 'REMOVE_PROD_CART',
        payload: upToDateCart
      };
    }
  } catch (error) {
    track('[productCart - removeCartItem] error => ', error);
    Sentry.captureException(error);
  }
};

export { loadCart, addCartItem, updateCartItemQuantity, removeCartItem };
