import { PrintAtHome, BookStructure } from '@/services/models';

const setPahName = (payload: string): { type: string; payload: string } => {
  const printAtHomeString = localStorage.getItem('printAtHome');
  if (printAtHomeString) {
    const printAtHome = <PrintAtHome>JSON.parse(printAtHomeString);
    printAtHome.name = payload;
    localStorage.setItem('printAtHome', JSON.stringify(printAtHome));
  }
  return {
    type: 'SET_PAH_NAME',
    payload
  };
};

const setPahEmail = (payload: string): { type: string; payload: string } => {
  const printAtHomeString = localStorage.getItem('printAtHome');
  if (printAtHomeString) {
    const printAtHome = <PrintAtHome>JSON.parse(printAtHomeString);
    printAtHome.email = payload;
    localStorage.setItem('printAtHome', JSON.stringify(printAtHome));
  }
  return {
    type: 'SET_PAH_EMAIL',
    payload
  };
};

const setPahAssetCode = (
  payload: string
): { type: string; payload: string } => {
  const printAtHomeString = localStorage.getItem('printAtHome');
  if (printAtHomeString) {
    const printAtHome = <PrintAtHome>JSON.parse(printAtHomeString);
    printAtHome.assetCode = payload;
    localStorage.setItem('printAtHome', JSON.stringify(printAtHome));
  }
  return {
    type: 'SET_PAH_ASSET_CODE',
    payload
  };
};

const populatePahKeys = (
  payload: BookStructure[] | null
): { type: string; payload: BookStructure[] | null } => {
  return {
    type: 'POPULATE_PAH_KEYS',
    payload
  };
};

export { setPahName, setPahEmail, setPahAssetCode, populatePahKeys };
