import { Character } from '@/services/models';

const updateCharacter = (
  payload: Character
): { type: string; payload: Character } => {
  if (
    typeof payload.name === 'undefined' ||
    typeof payload.currentSelection === 'undefined'
  ) {
    throw new Error('Invalid payload');
  }

  localStorage.setItem('characterPreview', JSON.stringify(payload));

  return {
    type: 'UPDATE_CHARACTER',
    payload
  };
};

const persistName = async (name: string) => {
  const characterString = localStorage.getItem('characterPreview');
  if (characterString) {
    const character = JSON.parse(characterString);

    character.name = name;

    localStorage.setItem('characterPreview', JSON.stringify(character));
  }
};

const persistLastName = async (lastName: string) => {
  const characterString = localStorage.getItem('characterPreview');
  if (characterString) {
    const character = JSON.parse(characterString);

    character.lastName = lastName;

    localStorage.setItem('characterPreview', JSON.stringify(character));
  }
};

const setCurrentCharacterName = (
  payload: string
): { type: string; payload: string } => {
  persistName(payload);

  return {
    type: 'SET_CURRENT_CHARACTER_NAME',
    payload
  };
};

const setCurrentCharacterLastName = (
  payload: string
): { type: string; payload: string } => {
  persistLastName(payload);

  return {
    type: 'SET_CURRENT_CHARACTER_LAST_NAME',
    payload
  };
};

const persistGender = async (gender: string) => {
  console.log('persistGender: ', gender);
  const characterString = localStorage.getItem('characterPreview');
  console.log('characterString:', characterString);
  if (characterString) {
    const character = JSON.parse(characterString);

    character.gender = gender;

    localStorage.setItem('characterPreview', JSON.stringify(character));
  }
};

const setCurrentCharacterGender = (
  payload: 'boy' | 'girl'
): { type: string; payload: 'boy' | 'girl' } => {
  persistGender(payload);

  return {
    type: 'SET_CURRENT_CHARACTER_GENDER',
    payload
  };
};

export {
  updateCharacter,
  setCurrentCharacterName,
  setCurrentCharacterLastName,
  setCurrentCharacterGender
};
