import * as React from 'react';

function CloseIcon(props: any) {
  const { color = '#6CB9D7' } = props;
  return (
    <svg
      width={13}
      height={13}
      viewBox="0 0 13 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect
        width={14}
        height={3}
        rx={1.5}
        transform="scale(-1 1) rotate(-45 6.056 19.508)"
        fill={color}
      />
      <rect
        x={0.404}
        y={9.899}
        width={14}
        height={3}
        rx={1.5}
        transform="rotate(-45 .404 9.9)"
        fill={color}
      />
    </svg>
  );
}

export default CloseIcon;
