import React, { useState } from 'react';
import cx from 'classnames';
import Typography from '@/components/shared/Typography';
import Button from '@/components/shared/Button';
import UploadIcon from '@/components/shared/Icon/ReactIcons/UploadIcon';
import StepTitle from '../StepTitle';
import { classifyCharacterImage as classifyCharacterImageService } from '@/services/classifyCharacterImage.service';
import { useDispatch } from 'react-redux';
import {
  setCurrentCharacterName,
  updateCharacter
} from '@/store/actions/characterPreview';
import path from 'path';
import { load, resize, saveImageBuffer } from '@/services/jimp.service';
import mime from 'mime-types';
import processHeic from '@/services/processHeic.service';
import { track } from '@/services/mixpanel.service';
import * as Sentry from '@sentry/nextjs';

export type FirstStepProps = {
  setCharacterBuilderStep: (step: number) => void;
  setShowCharacterBuilderModal: React.Dispatch<React.SetStateAction<boolean>>;
};

const convertFileToBase64: any = (file: File) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => {
      track(`[FirstStep - convertFileToBase64] error: ${error}`);
      Sentry.captureException(error);
      reject(error);
    };
    reader.readAsDataURL(file);
  });

const bufferFromFile: any = (file: File) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => {
      track(`[FirstStep - bufferFromFile] error: ${error}`);
      Sentry.captureException(error);
      reject(error);
    };
    reader.readAsArrayBuffer(file);
  });

const FirstStep: React.FC<FirstStepProps> = ({
  setCharacterBuilderStep,
  setShowCharacterBuilderModal
}) => {
  const dispatch = useDispatch();

  const inputFileRef: any = React.useRef(null);

  const [uploading, setUploading] = useState<boolean>(false);
  const [uploadError, setUploadError] = useState<string>('');

  const onFileChange = async (event: any) => {
    dispatch(setCurrentCharacterName(''));
    setUploadError('');
    setUploading(true);

    const file: File = event.target.files[0];
    const extension = path.extname(file.name.toLowerCase());

    try {
      // All valid extensions
      // Resize those extensions
      let base64 = await convertFileToBase64(file);

      if (['.heif', '.heic'].includes(extension)) {
        const mimeType = mime.lookup(file.name);
        console.log({ mimeType });

        const signedUrl = await processHeic(
          base64.replace('application/octet-stream', mimeType)
        );

        const newCharacter = await classifyCharacterImageService({
          imageUrl: signedUrl
        });
        console.log('newCharacter:', newCharacter);

        dispatch(updateCharacter(newCharacter));
        setUploading(false);
        setCharacterBuilderStep(2);
      } else if (['.jpg', '.jpeg', '.png', '.bmp'].includes(extension)) {
        const test = await bufferFromFile(file);
        const loadedFile = await load(test);
        const resizedFile = await resize(loadedFile);
        const buffer = await saveImageBuffer(resizedFile);
        base64 = 'data:image/jpeg;base64,' + buffer.toString('base64');

        const newCharacter = await classifyCharacterImageService({
          base64
        });

        dispatch(updateCharacter(newCharacter));
        setUploading(false);
        setCharacterBuilderStep(2);
      } else {
        setUploading(false);
        setUploadError('Invalid file format, use jpg, png, heic or bmp.');
      }
    } catch (error) {
      track(`[FirstStep] OnFileChange => ${error}`);
      Sentry.captureException(error);
    }
  };

  const trackUploadPhoto = () => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const ReactPixel = window.ReactPixel;
    ReactPixel.trackCustom('CustomUploadPhoto');

    const gtag = (window as any).gtag;
    gtag('event', 'CustomUploadPhoto', {
      event_category: 'CustomUploadPhoto',
      event_label: 'CustomUploadPhoto'
    });
  };

  const trackStartWithoutPhoto = () => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const ReactPixel = window.ReactPixel;
    ReactPixel.trackCustom('CustomStartWithoutPhoto');

    const gtag = (window as any).gtag;
    gtag('event', 'CustomStartWithoutPhoto', {
      event_category: 'CustomStartWithoutPhoto',
      event_label: 'CustomStartWithoutPhoto'
    });
  };

  return (
    <div className="flex flex-col items-center pb-6 pt-7 px-6">
      <div>
        <StepTitle
          step={1}
          setCharacterBuilderStep={setCharacterBuilderStep}
          setShowCharacterBuilderModal={setShowCharacterBuilderModal}
        />
      </div>
      <div className={cx('mt-8 mb-5')}>
        <img
          src="/images/home/steps/upload-photo.jpg"
          style={{ height: 300 }}
          alt="upload"
        />
      </div>
      <div className="">
        <Typography type="h3">Upload Photo</Typography>
      </div>
      <div
        className={cx(
          'flex flex-col items-center text-center font-sans w-75 pb-6 xl:pb-2 xl:w-576'
        )}
      >
        <div className={cx('mt-8')}>
          <Typography type="b2">
            Upload a photo of your child&lsquo;s face (no hats, please) to see
            them magically turn into their own illustration!
          </Typography>
        </div>
        <div className="mt-8 xl:flex">
          <input
            type="file"
            accept=".jpg,.jpeg,.png,.bmp,.heif,.heic"
            onChange={onFileChange}
            className="hidden"
            ref={inputFileRef}
          />
          <Button
            className="w-full xl:w-64 h-14"
            onClick={() => {
              inputFileRef.current.click();
              trackUploadPhoto();
            }}
            aria-label="Upload Photo"
          >
            <div className="flex justify-center items-center">
              {uploading ? (
                <svg
                  className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                    data-darkreader-inline-stroke=""
                    // style="--darkreader-inline-stroke:currentColor;"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    data-darkreader-inline-fill=""
                    // style="--darkreader-inline-fill:currentColor;"
                  ></path>
                </svg>
              ) : (
                <UploadIcon color="white" />
              )}
              <span className="font-extrabold text-14 ml-2">Upload Photo</span>
            </div>
          </Button>

          <span className="font-extrabold text-14 text-primary">
            {uploadError}
          </span>

          <Button
            className="w-full xl:w-64 mt-4 h-14 xl:mt-0 xl:ml-4 border border-primary "
            color="secondary"
            onClick={() => {
              setCharacterBuilderStep(2);
              trackStartWithoutPhoto();
            }}
            aria-label="START WITHOUT A PHOTO"
          >
            <span className="font-extrabold text-14 text-primary">
              START WITHOUT A PHOTO
            </span>
          </Button>
        </div>
        <div className={cx('mt-10')}>
          <Typography type="b2" color="primary" className="underline">
            <a target="_blank" href="/privacy-policy">
              We care about protecting your privacy.
            </a>
          </Typography>
        </div>
        <div className={cx('mt-1')}>
          <Typography type="b2">
            Don{"'"}t worry, the photos are deleted immediately after they are
            used.
          </Typography>
        </div>
      </div>
    </div>
  );
};

export default FirstStep;
