import { useViewport } from '@/hooks/useViewport';
import React from 'react';
import Modal from 'react-modal';
import Button from '@/components/shared/Button';
import CopyIcon from '@/components/shared/Icon/ReactIcons/CopyIcon';
import copy from 'copy-to-clipboard';

type Props = {
  show: boolean;
  setShowModalConfirmation: React.Dispatch<React.SetStateAction<boolean>>;
};

const ModalConfirmation: React.FC<Props> = ({
  show,
  setShowModalConfirmation
}) => {
  const viewport = useViewport();

  return (
    <Modal
      isOpen={show}
      style={{
        overlay: {
          display: 'flex',
          backgroundColor: 'rgb(86 86 86 / 100%)',
          paddingTop: '5vh',
          flexDirection: 'column',
          alignItems: 'stretch',
          zIndex: 1000
        },
        content: {
          borderWidth: 0,
          overflow: 'initial',
          borderRadius: viewport.isMobile
            ? '16px 16px 0px 0px'
            : '16px 16px 16px 16px',
          width: viewport.isMobile ? '100%' : 721,
          backgroundColor: '#FCFCFC',
          margin: viewport.isDesktop ? 'auto' : undefined,
          height: viewport.isDesktop ? 668 : '95vh',
          overflowX: 'hidden',
          overflowY: 'auto',
          position: 'relative',
          inset: '0'
        }
      }}
      contentLabel="modalBetterRepresentChild"
    >
      <h1
        className="w-full text-center pt-8 px-16"
        style={{
          fontSize: 32,
          fontFamily: 'Libre Baskerville',
          fontWeight: 700
        }}
      >
        Thank you for your feedback!
      </h1>
      <img
        className="py-8 mx-auto"
        src="/images/general/success-icon.svg"
        alt="success"
      />
      <p
        className="pt-6 text-center px-20"
        style={{ fontFamily: 'Nunito', fontSize: 16, fontWeight: 400 }}
      >
        We will forever be adding to our design library to help us better
        represent all children. We cannot do this without you.
      </p>
      <p
        className="pt-6 text-center px-20"
        style={{ fontFamily: 'Nunito', fontSize: 16, fontWeight: 400 }}
      >
        As a thank you for your partnership, we’d like to offer you 10% off any
        Keepsake Tales purchase. Please feel free to share the discount with
        your friends and family.
      </p>
      <p
        className="pt-6 text-center px-20"
        style={{ fontFamily: 'Nunito', fontSize: 16, fontWeight: 400 }}
      >
        DISCOUNT CODE
      </p>
      <div className="mt-0 flex">
        <div className="border-dashed border-2 border-primary text-center flex flex-row mt-4 mx-auto">
          <div className="h-16 flex items-center justify-center mt-2 pl-10">
            <p className="text-30 font-bold">KEEPSAKEFEEDBACK10</p>
          </div>
          <div className="w-9 h-16 flex items-center justify-center mt-2 mx-4 cursor-pointer">
            <CopyIcon
              onClick={() => {
                copy('KEEPSAKEFEEDBACK10', {
                  debug: true
                  // message: 'Press #{key} to copy'
                });
              }}
            />
          </div>
        </div>
      </div>
      <div className="flex">
        <Button
          className="mt-8 w-80 float-right mx-auto"
          onClick={() => {
            setShowModalConfirmation(false);
          }}
          aria-label="GO BACK TO CHARACTER EDITOR"
        >
          GO BACK TO CHARACTER EDITOR
        </Button>
      </div>
    </Modal>
  );
};

export default ModalConfirmation;
