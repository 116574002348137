import { setProperty } from '@/services/characterProcessor.service';
import { FamilyName } from '@/services/models';
import { updateCharacter } from '@/store/actions/characterPreview';
import React from 'react';
import { Dispatch } from 'redux';
import { Colors, Types } from '.';
import Typography from '../Typography';
import cx from 'classnames';

type Props = {
  key_: string;
  selectedFamily: FamilyName;
  selectedTab: string;
  characterPreview: any;
  tabItems: Colors | Types;
  dispatch: Dispatch<any>;
};

const RenderOptions: React.FC<Props> = ({
  key_,
  selectedFamily,
  selectedTab,
  characterPreview,
  tabItems,
  dispatch
}) => {
  return (
    <div
      className={cx(
        'w-11 h-11 xl:w-13 xl:h-13 ml-2 rounded-full flex justify-center items-center cursor-pointer',
        {
          'border-4 border-primary bg-white':
            key_ === characterPreview.currentSelection[selectedFamily].type,
          'border-4 border-primary':
            key_.trim() ===
            characterPreview.currentSelection[selectedFamily].color,
          'bg-lightBlue':
            key_ !== characterPreview.currentSelection[selectedFamily].type
        }
      )}
      style={{
        backgroundColor:
          selectedTab === 'colors' ? (tabItems as Colors)[key_] : undefined
      }}
      onClick={() => {
        const character = setProperty({
          character: characterPreview,
          familyName: selectedFamily,
          type: selectedTab === 'types' ? key_ : undefined,
          color: selectedTab === 'colors' ? key_.trim() : undefined
        });

        dispatch(updateCharacter(character));
      }}
    >
      <Typography type="b3b" color="white">
        {selectedTab === 'types' && (tabItems as Types)[key_].iconUrl && (
          <img
            className="p-2 max-h-12"
            src={(tabItems as Types)[key_].iconUrl}
            alt="avatar"
          />
          // eslint-disable-next-line indent
        )}
      </Typography>
    </div>
  );
};

export default RenderOptions;
