import React from 'react';
import FirstStep from './FirstStep';
import SecondStep from './SecondStep';
import ThirdStep from './ThirdStep';
import Modal from 'react-modal';
import { useViewport } from '@/hooks/useViewport';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import { setCurrentStep } from '@/store/actions/currentStep';

type Props = {
  showCharacterBuilderModal: boolean;
  setShowCharacterBuilderModal: React.Dispatch<React.SetStateAction<boolean>>;
  setShowPahModal?: React.Dispatch<React.SetStateAction<boolean>>;
  redirect?: string;
};

const CreateAvatar: React.FC<Props> = ({
  showCharacterBuilderModal,
  setShowCharacterBuilderModal,
  redirect,
  setShowPahModal
}) => {
  const dispatch = useDispatch();

  // const [characterBuilderStep, setCharacterBuilderStep] = useState<number>(1)
  const setCharacterBuilderStep = (step: number) => {
    dispatch(setCurrentStep(step));
  };

  const { currentStep } = useSelector((state: RootStateOrAny) => state);

  const viewport = useViewport();

  return (
    <div className="w-full">
      <Modal
        isOpen={showCharacterBuilderModal}
        style={{
          overlay: {
            display: 'flex',
            backgroundColor: 'rgb(86 86 86 / 80%)',
            flexDirection: 'column',
            alignItems: 'stretch',
            zIndex: 1000
          },
          content: {
            borderWidth: 0,
            overflow: 'initial',
            position: 'static',
            borderRadius: viewport.isMobile
              ? '16px 16px 0px 0px'
              : '16px 16px 16px 16px',
            width: viewport.isMobile ? '100%' : 970,
            backgroundColor: '#FCFCFC',
            margin: viewport.isDesktop ? 'auto' : undefined,
            height: viewport.isDesktop ? '90vh' : '100vh',
            overflowX: 'hidden',
            overflowY: 'auto',
            inset: undefined
          }
        }}
        contentLabel="Example Modal"
      >
        <div className="">
          {currentStep === 1 && (
            <FirstStep
              setCharacterBuilderStep={setCharacterBuilderStep}
              setShowCharacterBuilderModal={setShowCharacterBuilderModal}
            />
          )}
          {currentStep === 2 && (
            <SecondStep
              setCharacterBuilderStep={setCharacterBuilderStep}
              setShowCharacterBuilderModal={setShowCharacterBuilderModal}
            />
          )}
          {currentStep === 3 && (
            <ThirdStep
              setCharacterBuilderStep={setCharacterBuilderStep}
              setShowCharacterBuilderModal={setShowCharacterBuilderModal}
              setShowPahModal={setShowPahModal}
              redirect={redirect}
            />
          )}
        </div>
      </Modal>
    </div>
  );
};

export default CreateAvatar;
