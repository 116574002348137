import React from 'react';

type Props = {
  fill: string;
};

const Face: React.FC<Props> = ({ fill }) => (
  <svg
    width="26"
    height="8"
    viewBox="0 0 26 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M25.6479 2.18385C25.4433 1.62817 24.9269 1.15845 24.1132 0.787399C22.2816 -0.0477012 18.8938 -0.340472 16.395 0.614981C16.0419 0.749187 15.7192 0.952751 15.4461 1.21368C15.173 1.47461 14.9549 1.78761 14.8047 2.13421C13.6314 1.76853 12.3736 1.77554 11.2044 2.15429C11.0553 1.80319 10.8369 1.48584 10.5621 1.22131C10.2873 0.956782 9.96179 0.750555 9.60527 0.614981C7.10617 -0.340472 3.71872 -0.0478064 1.88693 0.787399C1.07329 1.15845 0.556967 1.62828 0.352348 2.18385C0.286175 2.35765 -0.677677 5.00214 1.20209 6.70285C1.99595 7.42118 3.80832 7.86913 5.65379 7.86902C7.44687 7.86902 9.2713 7.4459 10.2211 6.43669C11.1104 5.49175 11.5544 4.30475 11.4584 3.17961C12.4575 2.83761 13.5406 2.82946 14.5448 3.15636C14.4407 4.28856 14.8841 5.48534 15.7795 6.43669C16.7293 7.446 18.5534 7.86913 20.3467 7.86902C22.192 7.86902 24.0046 7.42107 24.7983 6.70285C26.678 5.00193 25.7141 2.35765 25.6479 2.18385ZM9.45476 5.71562C7.99465 7.26695 3.08996 6.99258 1.90758 5.92277C0.545416 4.69032 1.30209 2.64549 1.33565 2.55712L1.33862 2.54891C1.43909 2.27391 1.78862 1.98828 2.32305 1.74463C3.92213 1.01547 7.01737 0.751838 9.22894 1.59735C9.51498 1.70676 9.76862 1.88694 9.96608 2.12103C10.1635 2.35512 10.2984 2.63545 10.358 2.93584C10.5681 3.85237 10.2303 4.89147 9.45476 5.71562ZM24.0923 5.92277C22.9099 6.99258 18.0052 7.26695 16.5451 5.71562C15.7695 4.89147 15.4317 3.85239 15.6418 2.93607C15.7014 2.63569 15.8363 2.35533 16.0337 2.12124C16.2312 1.88715 16.4847 1.70697 16.7708 1.59756C17.736 1.22851 18.8696 1.0707 19.9737 1.0707C21.3992 1.0707 22.7754 1.3337 23.6768 1.74463C24.2112 1.98828 24.5605 2.27391 24.6611 2.5488L24.6642 2.55702C24.6972 2.6435 25.4551 4.68969 24.0923 5.92277Z"
      fill={fill}
    />
  </svg>
);

export default Face;
