const setCurrentStep = (payload: number): { type: string; payload: number } => {
  localStorage.setItem('currentStep', `${payload}`);

  return {
    type: 'SET_CURRENT_STEP',
    payload
  };
};

export { setCurrentStep };
