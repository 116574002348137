import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import { RootStateOrAny, useSelector } from 'react-redux';
import { AssetFamily, CharacterAssets, FamilyName } from '@/services/models';
import CharImage from '../CharImage';
import FamilyIcon from './icons';
import { processCharactersAssetsURLs } from '@/services/characterProcessor.service';
import findService from '@/services/find.service';

export type IconDivProps = {
  familyName: string;
  selected: boolean;
  setSelected: () => void;
};

export type IllustrationPreviewProps = {
  selectedFamily: FamilyName;
  setSelectedFamily: React.Dispatch<React.SetStateAction<FamilyName>>;
  configSetupBuildKey?: CharacterAssets;
};

const IconDiv: React.FC<IconDivProps> = ({
  familyName,
  selected,
  setSelected
}) => {
  return (
    <div
      onClick={setSelected}
      className={cx(
        'rounded-full border-white cursor-pointer flex justify-center items-center',
        {
          'bg-intermediaryGray border-2 w-14 h-14 xl:w-20 xl:h-20': !selected,
          'border-8 bg-primary border-white shadow-2 w-18 h-18 xl:w-24 xl:h-24':
            selected
        }
      )}
    >
      <FamilyIcon type={familyName} selected={selected} />
    </div>
  );
};

const IllustrationPreview: React.FC<IllustrationPreviewProps> = ({
  selectedFamily,
  setSelectedFamily
}) => {
  const { configSetupBuildKey, characterPreviewBookKey, characterPreview } =
    useSelector((state: RootStateOrAny) => state);

  const [charImages, setCharImages] = useState<string[]>([]);

  useEffect(() => {
    if (
      characterPreview &&
      characterPreviewBookKey &&
      characterPreviewBookKey.cover
    ) {
      const charImages_ = processCharactersAssetsURLs(
        characterPreview.currentSelection,
        characterPreviewBookKey.cover.characters[0],
        'preview'
      );

      setCharImages(charImages_);
    }
  }, [characterPreview]);

  const [isLoading, setIsLoading] = useState(false);
  const [leftBar, setLeftBar] = useState<AssetFamily[]>([]);
  const [rightBar, setRightBar] = useState<AssetFamily[]>([]);

  async function initialFetches() {
    setIsLoading(true);
    const items = configSetupBuildKey;
    if (items && items.length) {
      setSelectedFamily(items[0].familyName);
      const left = items.filter(
        (_: AssetFamily, index: number) => index % 2 === 0
      );
      const right = items.filter(
        (_: AssetFamily, index: number) => index % 2 !== 0
      );
      setLeftBar(left);
      setRightBar(right);
    }
    setIsLoading(false);
  }

  useEffect(() => {
    initialFetches();
  }, [configSetupBuildKey]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  const isRightBarSelected = !!findService(
    rightBar,
    'familyName',
    selectedFamily
  );

  return (
    <div className="flex w-auto sm:w-auto">
      <div className="flex flex-row justify-between">
        <div className="w-1/6 flex flex-col items-center">
          {leftBar.map((item, index) => (
            <div
              key={index}
              className={cx('flex justify-center items-center', {
                'mt-15': isRightBarSelected,
                'mt-2.5': !isRightBarSelected
              })}
            >
              <IconDiv
                familyName={item.familyName}
                selected={item.familyName === selectedFamily}
                setSelected={() => setSelectedFamily(item.familyName)}
              />
            </div>
          ))}
        </div>

        {charImages && (
          <div className="xl:order-last">
            <CharImage charImages={charImages} />
          </div>
        )}

        <div className="w-1/6 xl:ml-10 flex flex-col items-center">
          {rightBar.map((item, index) => (
            <div
              key={index}
              className={cx('flex justify-center items-center', {
                'mt-15': !isRightBarSelected,
                'mt-2.5': isRightBarSelected
              })}
            >
              <IconDiv
                familyName={item.familyName}
                selected={item.familyName === selectedFamily}
                setSelected={() => setSelectedFamily(item.familyName)}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default IllustrationPreview;
