import React from 'react';
import cx from 'classnames';

export type CharImageProps = {
  charImages: string[];
};

const CharImage: React.FC<CharImageProps> = ({ charImages }) => {
  return (
    <div className="grid">
      {charImages.map(charImage => {
        if (charImage.indexOf('none') === -1) {
          return (
            <div
              key={charImage}
              className="row-span-full col-start-1 self-center"
            >
              <img
                src={charImage}
                onError={({ target }: any) => {
                  target.src =
                    'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=';
                }}
                alt="champion"
              />
            </div>
          );
        }
        return null;
      })}
    </div>
  );
};

export default CharImage;
