/* eslint-disable indent */
import React, { useEffect, useState } from 'react';
import Typography from '../Typography';
import cx from 'classnames';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import { setProperty } from '@/services/characterProcessor.service';
import { FamilyName } from '@/services/models';
import { updateCharacter } from '@/store/actions/characterPreview';
import findService from '@/services/find.service';
import RenderOptions from './RenderOptions';

export type TabProps = {
  selectedTab: string;
  tabName: 'types' | 'colors';
  setSelectedTab: (arg: 'types' | 'colors') => void;
  tabItem: Item;
};

export type Item = {
  category: string;
  mandatory: boolean;
  types: Types;
  colors: Colors;
};

export type Colors = {
  [index: string]: string;
};

export type Types = {
  [index: string]: {
    iconUrl: string;
  };
};

const Tab: React.FC<TabProps> = ({
  selectedTab,
  setSelectedTab,
  tabName,
  tabItem
}) => {
  const tabQty =
    tabName === 'colors'
      ? tabItem && tabItem.colors
        ? Object.keys(tabItem.colors).length
        : 0
      : tabItem && tabItem.types // eslint-disable-next-line indent
      ? Object.keys(tabItem.types).length // eslint-disable-next-line indent
      : 0;

  return (
    <div
      onClick={() => setSelectedTab(tabName)}
      className={cx(
        'flex flex-row w-49 h-14 cursor-pointer items-center justify-between px-5 rounded-t-lg',
        {
          'bg-lightGray': selectedTab === tabName,
          'bg-basicGray': selectedTab !== tabName,
          'bg-opacity-60': selectedTab === tabName
        }
      )}
    >
      <Typography type="b3b" className="uppercase">
        {tabName}
      </Typography>
      &nbsp;
      <div className="bg-grey flex justify-center items-center border-4 border-primary rounded-full w-6 h-6 p-4">
        <Typography color="white" style={{ fontSize: 12 }}>
          {tabQty}
        </Typography>
      </div>
    </div>
  );
};

type Props = {
  selectedFamily: FamilyName;
};

const TabbedAvatar: React.FC<Props> = ({ selectedFamily }) => {
  const dispatch = useDispatch();

  const [selectedItem, setSelectedItem] = useState<Item>();

  const { configSetupBuildKey, characterPreview } = useSelector(
    (state: RootStateOrAny) => state
  );

  const [selectedTab, setSelectedTab] = useState<'types' | 'colors'>('types');
  const [tabItems, setTabItems] = useState<Types | Colors>();

  useEffect(() => {
    if (selectedFamily && configSetupBuildKey) {
      const item: Item = findService(
        configSetupBuildKey,
        'familyName',
        selectedFamily
      );
      if (item) {
        setSelectedItem(item);

        const tabItems_ = selectedTab === 'types' ? item?.types : item?.colors;
        if (tabItems_) setTabItems(tabItems_);
      }
    }
  }, [selectedFamily, selectedTab, configSetupBuildKey]);

  const trackSwitchChampionAsset = (
    familyName: string,
    type: string | undefined
  ) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const ReactPixel = window.ReactPixel;
    ReactPixel.trackCustom('CustomSwitchChampionAsset', {
      familyName,
      type
    });

    const gtag = (window as any).gtag;
    gtag('event', 'CustomSwitchChampionAsset', {
      event_category: 'CustomSwitchChampionAsset',
      event_label: 'CustomSwitchChampionAsset',
      value: {
        familyName,
        type
      }
    });
  };

  return (
    <div className="xl:w-98">
      <div className="flex flex-row xl:w-62 justify-between">
        {selectedItem && (
          <>
            <Tab
              tabName="types"
              selectedTab={selectedTab}
              setSelectedTab={setSelectedTab}
              tabItem={selectedItem}
            />
            <Tab
              tabName="colors"
              selectedTab={selectedTab}
              setSelectedTab={setSelectedTab}
              tabItem={selectedItem}
            />
          </>
        )}
      </div>
      <div className="h-44 bg-lightGray bg-opacity-60 rounded-b-lg xl:w-62 xl:h-60 flex flex-wrap py-4 pl-3 overflow-auto gap-x-2 xl:gap-x-4 gap-y-2">
        {tabItems && characterPreview && (
          <>
            {selectedTab === 'types' && !selectedItem?.mandatory && (
              <div
                className={cx(
                  'w-11 h-11 xl:w-13 xl:h-13 ml-2 rounded-full flex justify-center items-center cursor-pointer',
                  {
                    'border-4 border-primary bg-white':
                      characterPreview.currentSelection[selectedFamily].type ===
                      'none',
                    'bg-lightBlue':
                      characterPreview.currentSelection[selectedFamily].type !==
                      'none'
                  }
                )}
                onClick={() => {
                  const type_ = selectedTab === 'types' ? 'none' : undefined;

                  const character = setProperty({
                    character: characterPreview,
                    familyName: selectedFamily,
                    type: type_
                  });

                  trackSwitchChampionAsset(selectedFamily, type_);

                  dispatch(updateCharacter(character));
                }}
              >
                <Typography type="b3b" color="white">
                  {selectedTab === 'types' && (
                    <img
                      className="p-2 max-h-12"
                      src="/images/preview/icons/none.svg"
                      alt="no image"
                    />
                  )}
                </Typography>
              </div>
            )}
            {(selectedFamily === 'body' || selectedFamily === 'face') &&
            selectedTab === 'colors'
              ? Object.keys(tabItems) // eslint-disable-next-line indent
                  .sort() // eslint-disable-next-line indent
                  .reverse() // eslint-disable-next-line indent
                  .map((key: string) => (
                    <RenderOptions
                      key={key}
                      key_={key}
                      selectedFamily={selectedFamily}
                      selectedTab={selectedTab}
                      characterPreview={characterPreview}
                      tabItems={tabItems}
                      dispatch={dispatch}
                    />
                  ))
              : Object.keys(tabItems)
                  // .sort()
                  .map((key: string) => (
                    <RenderOptions
                      key={key}
                      key_={key}
                      selectedFamily={selectedFamily}
                      selectedTab={selectedTab}
                      characterPreview={characterPreview}
                      tabItems={tabItems}
                      dispatch={dispatch}
                    />
                  ))}
          </>
        )}
      </div>
    </div>
  );
};

export default TabbedAvatar;
