import React from 'react';

type Props = {
  fill: string;
};

const Hearing: React.FC<Props> = ({ fill }) => (
  <svg
    width="10"
    height="18"
    viewBox="0 0 10 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.37332 10.3683C9.1343 9.56296 9.64946 8.55729 9.8583 7.46945C10.1691 5.70936 10.1002 3.3626 7.78645 1.74059C5.47275 0.118581 2.64099 1.29201 1.5704 3.39715C0.499814 5.50229 0.53443 8.12511 0.672601 10.1613C0.672601 10.1613 0.0853971 10.7827 0.016259 13.6123C-0.0528791 16.442 0.430829 17.167 1.43228 16.9944C2.43373 16.8219 1.50122 14.6821 3.74599 13.6813C5.99077 12.6805 6.12873 9.70162 4.74723 8.99963C3.36573 8.29763 2.019 8.63183 1.67384 8.99963C1.54264 7.96619 1.62489 6.91686 1.9155 5.91644C2.39894 4.22545 3.08965 3.25908 5.36878 3.36261C7.64792 3.46615 8.58049 5.39876 8.54603 6.64117C8.51156 7.88359 8.37332 10.3683 8.37332 10.3683Z"
      fill={fill}
    />
  </svg>
);

export default Hearing;
