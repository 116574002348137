import axios from 'axios';
import { track } from './mixpanel.service';
import { CharacterAssets } from './models';
import * as Sentry from '@sentry/nextjs';

export const getConfigSetupBuildKey = async (): Promise<CharacterAssets> => {
  try {
    const { data } = await axios.get('/keys/character-options-key.json');
    // console.log('data:', data)
    return data;
  } catch (error: any) {
    track(
      '[characterOptions.service] Error while downloading ConfigSetupBuildKey =>',
      error.response
    );

    Sentry.captureException(error);

    console.log(error.response, 'error??');
    return error;
  }
};
