import axios from 'axios';
import { track } from './mixpanel.service';
import * as Sentry from '@sentry/nextjs';

export const classifyCharacterImage = async (payload: {
  base64?: string;
  imageUrl?: string;
}): Promise<any> => {
  const { base64, imageUrl } = payload;

  try {
    const { data } = await axios.post(
      process.env.NEXT_PUBLIC_API_BASE_URL + '/azure-classification',
      { base64, imageUrl }
    );
    return data;
  } catch (error: any) {
    track('[classifyCharacterImage] Error returned from AI =>', error);

    console.log(error.response, 'error??');

    Sentry.captureException(error);

    throw error;
  }
};
