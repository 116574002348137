import React from 'react';

type Props = {
  fill: string;
};

const Body: React.FC<Props> = ({ fill }) => (
  <svg
    width="29"
    height="15"
    viewBox="0 0 29 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M22.4977 4.97329C21.8566 4.05318 19.7593 1.09286 19.4245 0.732801C19.2991 0.598576 17.6233 0.267377 16.2383 0.143555C16.5707 0.867697 18.1581 6.05783 18.7952 7.05613C19.3465 6.97861 21.8341 5.52764 22.4977 4.97329Z"
      fill={fill}
    />
    <path
      d="M6.11719 4.97329C6.75792 4.05318 8.85526 1.09286 9.19008 0.732801C9.31547 0.598576 10.9913 0.267377 12.3763 0.143555C12.0438 0.867697 10.4564 6.05783 9.81939 7.05613C9.26908 6.97861 6.78011 5.52764 6.11719 4.97329Z"
      fill={fill}
    />
    <path
      d="M8.64648 11.924C11.4299 13.5635 17.3552 13.4149 20.2442 11.7898C20.2442 11.7898 19.1493 2.69303 18.7157 0.537719C17.6645 0.24813 16.2368 0.0162568 14.754 0.000820934C12.9366 -0.0179705 11 0.290074 9.82004 0.49074C9.51043 3.23798 9.01022 7.86269 8.64648 11.924Z"
      fill={fill}
    />
    <path
      d="M24.8767 10.0825C24.9567 10.2179 23.3068 10.9072 23.2148 10.7791C21.8429 8.8645 19.1007 6.24509 18.6274 5.76843C18.6274 5.76843 20.0813 4.58951 21.3297 4.98062C22.3224 5.29178 23.8157 8.29115 24.8767 10.0825Z"
      fill={fill}
    />
    <path
      d="M25.736 11.8377C26.7081 12.3663 27.5762 12.641 27.9136 12.3263C27.4297 11.9694 25.4345 10.8408 23.9073 9.75901C23.7522 9.64798 23.175 10.4879 23.323 10.7433C23.303 11.579 24.2963 11.8556 24.9647 13.1241C25.1557 13.4879 25.4066 13.2425 25.4242 13.052C25.4549 12.7128 25.3044 12.3664 25.1516 11.7756C25.0433 11.3602 25.463 11.6891 25.736 11.8377Z"
      fill={fill}
    />
    <path
      d="M28.3206 11.5905C27.7246 11.2901 26.8369 10.8376 25.1617 9.94198C24.6661 9.67717 24.2856 10.4399 24.4772 10.5482C25.4721 11.1083 27.754 12.3492 28.3206 11.5905Z"
      fill={fill}
    />
    <path
      d="M28.3879 10.9977C27.8072 10.6684 26.0516 10.2675 24.3969 9.42287C23.5627 8.99681 22.3842 10.2516 23.2862 11.0808C23.827 11.5783 25.258 11.1408 25.8203 10.8635C26.8569 11.1534 28.0811 11.3529 28.3879 10.9977Z"
      fill={fill}
    />
    <path
      d="M3.69944 10.1988C3.62236 10.3359 5.31736 10.9364 5.4063 10.8059C6.73694 8.85688 9.43437 6.14838 9.8998 5.65786C9.8998 5.65786 8.38916 4.56604 7.13428 5.00929C6.1364 5.36352 4.71719 8.3861 3.69944 10.1988Z"
      fill={fill}
    />
    <path
      d="M2.88649 11.9542C1.91776 12.5198 1.04545 12.8297 0.693649 12.5376C1.17321 12.1635 3.15929 10.9591 4.67515 9.82341C4.82963 9.70767 5.44103 10.5061 5.29899 10.7644C5.34507 11.5859 4.34728 11.9053 3.70988 13.1854C3.52745 13.5514 3.26538 13.3215 3.24205 13.137C3.19984 12.8054 3.34162 12.4565 3.47767 11.8681C3.57503 11.4508 3.16011 11.7947 2.88649 11.9542Z"
      fill={fill}
    />
    <path
      d="M0.258421 11.8328C0.853449 11.5091 1.73948 11.0219 3.40978 10.0612C3.90411 9.77727 4.31377 10.5099 4.12243 10.6258C3.13149 11.2242 0.857371 12.5537 0.258421 11.8328Z"
      fill={fill}
    />
    <path
      d="M0.172561 11.2526C0.75092 10.9017 2.51843 10.4246 4.1695 9.51427C5.00186 9.05536 6.23639 10.2351 5.34829 11.0938C4.81568 11.6089 3.3506 11.247 2.77164 10.9995C1.72949 11.3339 0.494904 11.588 0.172561 11.2526Z"
      fill={fill}
    />
  </svg>
);

export default Body;
