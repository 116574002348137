import { BookStructure } from '@/services/models';

// Action Creator
const loadCharacterPreviewBookKey = (payload: BookStructure) => {
  console.log('[loadCharacterPreviewBookKey] payload:', payload);
  return {
    type: 'LOAD_CHARACTER_PREVIEW_BOOK_KEY',
    payload
  };
};

export { loadCharacterPreviewBookKey };
