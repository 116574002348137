import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';

type Props = {
  selectedFile: string;
  returnFiles: (files: File[]) => void;
};

const DropZone: React.FC<Props> = ({ selectedFile, returnFiles }) => {
  const onDrop = useCallback(acceptedFiles => {
    returnFiles(acceptedFiles);
  }, []);

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  return (
    <div
      className="h-40 mt-4"
      style={{ borderColor: '#6BBCFF', borderWidth: 2, borderStyle: 'dashed' }}
      {...getRootProps()}
    >
      <input {...getInputProps()} />
      {selectedFile ? (
        <div
          className="w-full text-center mt-8 pt-2"
          style={{
            fontFamily: 'Nunito',
            fontWeight: 800,
            fontSize: 14,
            color: '#6BBCFF'
          }}
        >
          SELECTED FILE: {selectedFile}
        </div>
      ) : (
        <>
          <img
            className="mt-8 m-auto"
            src="/images/general/folder-icon.svg"
            alt="upload"
          />
          <div
            className="w-full text-center pt-2"
            style={{
              fontFamily: 'Nunito',
              fontWeight: 800,
              fontSize: 14,
              color: '#6BBCFF'
            }}
          >
            UPLOAD A PHOTO
          </div>
          <div
            className="w-full text-center"
            style={{
              fontFamily: 'Nunito',
              fontWeight: 800,
              fontSize: 14,
              color: '#6BBCFF'
            }}
          >
            (OPTIONAL)
          </div>
        </>
      )}
    </div>
  );
};

export default DropZone;
