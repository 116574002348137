import axios, { AxiosResponse } from 'axios';
import { track } from '../mixpanel.service';
import * as Sentry from '@sentry/nextjs';

const submitContactForm = async (payload: {
  name: string;
  email: string;
  subject: string;
  message: string;
  fileName?: string;
  file?: string;
}): Promise<AxiosResponse> => {
  try {
    const { data } = await axios.post(
      process.env.NEXT_PUBLIC_API_BASE_URL + '/contact-us',
      payload
    );
    return data;
  } catch (error: any) {
    track('[AirTable.service] Error while submitting contact form =>', error);

    Sentry.captureException(error);

    console.log(error.response, 'error??');

    throw error;
  }
};

export { submitContactForm };
