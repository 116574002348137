import React from 'react';
import cx from 'classnames';
import { ThemeColors } from '@/@types/theme';

export type ButtonProps = {
  color?: ThemeColors;
  textColor?: ThemeColors;
} & React.ButtonHTMLAttributes<HTMLButtonElement>;

const Button: React.FC<ButtonProps> = ({
  children,
  color = 'primary',
  textColor = 'white',
  className,
  ...props
}) => {
  return (
    <button
      className={cx(
        'pr-2 pl-4 py-2 rounded-full uppercase',
        `text-${textColor}`,
        `bg-${color}`,
        className
      )}
      type="button"
      {...props}
    >
      {children}
    </button>
  );
};

export type RoundButtonProps = Omit<ButtonProps, 'color'> & {
  color?: ThemeColors;
  size?: 'sm' | 'md';
  isSecondary?: boolean;
  shadowLess?: boolean;
  disabled?: boolean;
};

export const RoundButton: React.FC<RoundButtonProps> = ({
  children,
  className,
  color,
  size,
  shadowLess,
  ...props
}) => {
  return (
    <button
      className={cx(
        'rounded-full flex items-center justify-center cursor-pointer border-2 w-14 h-14',
        {
          'border-primary, border': color === 'white',
          [`bg-${color}`]: color,
          'shadow-lg': !shadowLess && color === 'white'
        },
        className
      )}
      {...props}
    >
      {children}
    </button>
  );
};

RoundButton.defaultProps = {
  size: 'sm',
  color: 'primary',
  type: 'button'
};

Button.defaultProps = {
  color: 'primary',
  type: 'button'
} as ButtonProps;

export default Button;
