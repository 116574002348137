export const LINKS = [
  {
    label: 'Buy Now',
    href: '/shop'
  },
  {
    label: 'FAQ',
    href: '/faq'
  },
  {
    label: 'Free Resources',
    href: '/resources'
  },
  {
    label: 'Privacy Policy',
    href: '/privacy-policy'
  },
  {
    label: 'Our Mission',
    href: '/about-us'
  },
  {
    label: 'Contact Us',
    href: '/faq#contact-us'
  },
  {
    label: 'Return / Refund Policy',
    href: '/refund-policy'
  },
  {
    label: 'Terms of Service',
    href: '/terms-of-service'
  }
];
