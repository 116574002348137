export default {
  desktop: '(min-width: 1280px)'
  /* desktopDown: '(max-width: 991.98px)',

  desktopSmall: '(min-width: 991px) and (max-width: 1336px)',

  tablet: '(min-width: 576px)',
  tabletOnly: '(min-width: 576px) and (max-width: 991.98px)',
  tabletDown: '(max-width: 991.98px)',

  largeMobileDown: '(max-width: 768px)',
  mediumMobile: '(min-width: 360px)' */
};
