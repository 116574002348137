import React from 'react';

type Props = {
  fill: string;
};

const Face: React.FC<Props> = ({ fill }) => (
  <svg
    width="22"
    height="20"
    viewBox="0 0 22 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.1672 20C11.0886 20 11.0319 19.9988 10.9999 19.9977C10.5723 20.0109 5.71352 20.075 3.38847 16.4978C3.13564 16.1134 2.90461 15.7157 2.69638 15.3063C2.00215 14.9231 1.38 14.4259 0.856927 13.8365C0.485914 13.4304 0.223526 12.9402 0.0933396 12.41C-0.0368469 11.8798 -0.030765 11.3261 0.111058 10.7988C0.288797 10.1677 0.654892 9.72516 1.11565 9.58444C1.34222 9.51166 1.58157 9.48515 1.81897 9.50653C1.80557 9.21703 1.78757 8.90555 1.76237 8.57046C1.61272 6.58296 2.13424 4.72928 3.27059 3.21011C4.29475 1.81227 5.8062 0.831957 7.51817 0.455157C9.69992 -0.00375733 10.8315 -0.00322446 10.9999 0.00063101C11.1689 -0.00311106 12.2998 -0.00375733 14.4817 0.455157C16.1937 0.831951 17.7052 1.81226 18.7294 3.21011C19.8657 4.72962 20.3873 6.58296 20.2376 8.57046C20.2166 8.84964 20.1928 9.11056 20.1702 9.35742C20.1658 9.40754 20.1613 9.45693 20.1569 9.50554C20.4023 9.48236 20.65 9.50936 20.8843 9.58488C21.3451 9.72561 21.7112 10.1682 21.8889 10.7992C22.0307 11.3266 22.0369 11.8802 21.9067 12.4104C21.7765 12.9406 21.5142 13.4308 21.1432 13.8369C20.6201 14.4263 19.9979 14.9235 19.3037 15.3067C19.0954 15.7161 18.8643 16.1139 18.6115 16.4983C16.46 19.8077 12.1396 20 11.1672 20Z"
      fill={fill}
    />
  </svg>
);

export default Face;
