import { loadCart } from '@/store/actions/productCart';
import {
  getAllProducts,
  getCart,
  shopifyCartIsValid
} from '@/services/shopify/storefront.service';
import { getProduct } from '@/services/product.service';
import { getCharacterPreviewBookKey } from '@/services/characterPreviewBookKey.service';

import { loadConfigSetupBuildKey } from '@/store/actions/configSetupBuildKey';
import { loadProductList } from '@/store/actions/productList';
import { populateBooksBuildKeys } from '@/store/actions/booksBuildKeys';
import { loadCharacterPreviewBookKey } from '@/store/actions/characterPreviewBookKey';
import { initializeCharacter } from '@/services/characterProcessor.service';
import { loadPahKeys } from '@/services/printAtHome.service';
import { updateCharacter } from '@/store/actions/characterPreview';
import {
  setPahName,
  setPahEmail,
  setPahAssetCode,
  populatePahKeys
} from '@/store/actions/printAtHome';

import { getConfigSetupBuildKey } from '@/services/characterOptions.service';

import {
  Cart,
  Product,
  CharacterAssets,
  BookStructure
} from '@/services/models';
import { Dispatch } from 'redux';
import { setCharacterConfigured } from './actions/characterConfigured';
import { setCurrentStep } from './actions/currentStep';
import { setCurrentStepPah } from './actions/currentStepPah';
import { track } from '@/services/mixpanel.service';
import * as Sentry from '@sentry/nextjs';

const isJson = (json: string) => {
  try {
    return JSON.parse(json) && !!json;
  } catch (e) {
    return false;
  }
};

const isCartStructureGood = (cart: Cart) => {
  // Validate product cart
  if (cart?.cartItems === undefined) return false;
  if (cart?.discount === undefined) return false;
  if (cart?.id === undefined || cart?.id === 'error_cart') return false;
  if (cart?.totalPrice === undefined) return false;
  if (cart?.webUrl === undefined) return false;
  return true;
};

export const initializeCart = async (
  productCart: Cart,
  dispatch: Dispatch<any>
): Promise<void> => {
  // if your cart is good, use it, otherwise reset it
  let cart: Cart | null = productCart || (await getCart(null));
  const productCartString: string | null = localStorage.getItem('productCart');

  // if our cart is bad, try to get from local storage.
  // If we don't find it, create a new one.
  if (!productCart && productCartString) {
    try {
      cart = JSON.parse(productCartString);
    } catch (error) {
      cart = await getCart(null);
    }
  }

  if (
    cart &&
    (!isCartStructureGood(cart) || !(await shopifyCartIsValid(cart.id)))
  ) {
    cart = await getCart(null);
  }

  if (cart) dispatch(loadCart(cart));
};

const fetchConfigSetupBuildKey = async (
  configSetupBuildKey: CharacterAssets,
  dispatch: Dispatch<any>
): Promise<CharacterAssets | void> => {
  if (!configSetupBuildKey) {
    try {
      const configSetupBuildKey: CharacterAssets =
        await getConfigSetupBuildKey();
      dispatch(loadConfigSetupBuildKey(configSetupBuildKey));
      return configSetupBuildKey;
    } catch (error) {
      track('[initializeState - fetchConfigSetupBuildKey] error => ', error);
      Sentry.captureException(error);
    }
  }
};

const fetchCharacterPreviewBookKey = async (
  dispatch: Dispatch<any>
): Promise<BookStructure | void> => {
  try {
    const characterPreviewBookKey = await getCharacterPreviewBookKey();
    dispatch(loadCharacterPreviewBookKey(characterPreviewBookKey));
    return characterPreviewBookKey;
  } catch (error) {
    track('[initializeState - fetchCharacterPreviewBookKey] error => ', error);
    Sentry.captureException(error);
  }
};

export const loadPreviewRelatedContent = async (
  configSetupBuildKey: CharacterAssets,
  dispatch: Dispatch<any>
): Promise<void> => {
  if (!configSetupBuildKey) {
    Promise.all([
      fetchConfigSetupBuildKey(configSetupBuildKey, dispatch),
      fetchCharacterPreviewBookKey(dispatch)
    ])
      .then(result => {
        const [configSetupBuildKey] = result;

        if (configSetupBuildKey) {
          const characterString = localStorage.getItem('characterPreview');

          let initializedCharacter =
            characterString && isJson(characterString)
              ? JSON.parse(characterString)
              : initializeCharacter(configSetupBuildKey);

          if (
            typeof initializedCharacter.name === 'undefined' ||
            typeof initializedCharacter.currentSelection === 'undefined'
          ) {
            initializedCharacter = initializeCharacter(configSetupBuildKey);
            // throw new Error('Invalid payload')
          }

          if (!characterString) {
            localStorage.setItem(
              'characterPreview',
              JSON.stringify(initializedCharacter)
            );
          }

          dispatch(updateCharacter(initializedCharacter));
        }
      })
      .catch(error => {
        track('[initializeState - loadPreviewRelatedContent] error => ', error);
        Sentry.captureException(error);
      });
  }
};

export const fetchProductList = async (
  productList: Product[],
  dispatch: Dispatch<any>
): Promise<void> => {
  try {
    if (!productList) {
      const productList: Product[] = await getAllProducts();
      dispatch(loadProductList(productList));

      // Gets all the books build keys
      const bookKeysPromises: Promise<BookStructure>[] = [];
      productList.forEach((product: Product) => {
        bookKeysPromises.push(getProduct(product.handle));
      });

      Promise.all(bookKeysPromises).then(bookKeys => {
        dispatch(populateBooksBuildKeys(bookKeys.filter(bk => bk !== null)));
      });
    }
  } catch (error) {
    track('[initializeState - fetchProductList] error => ', error);
    Sentry.captureException(error);
  }
};

export const populateCharacterConfigured = (dispatch: Dispatch<any>) => {
  const isPopulated = localStorage.getItem('characterConfigured') === 'true';
  dispatch(setCharacterConfigured(isPopulated));
};

export const populateCurrentStep = (dispatch: Dispatch<any>) => {
  const currentStep = parseInt(localStorage.getItem('currentStep') || '1', 10);
  dispatch(setCurrentStep(!isNaN(currentStep) ? currentStep : 1));
};

export const populateCurrentStepPah = (dispatch: Dispatch<any>) => {
  const currentStepPah = parseInt(
    localStorage.getItem('currentStepPah') || '1',
    10
  );
  dispatch(setCurrentStepPah(!isNaN(currentStepPah) ? currentStepPah : 1));
};

export const populatePrintAtHome = async (dispatch: Dispatch<any>) => {
  // state item: printAtHome
  const printAtHome = localStorage.getItem('printAtHome');
  const isAlreadyInitialized = printAtHome && isJson(printAtHome);
  if (isAlreadyInitialized) {
    dispatch(setPahName(JSON.parse(printAtHome).name));
    dispatch(setPahEmail(JSON.parse(printAtHome).email));

    // If asset code is from valentines reset state to first room asset code
    const currentAssetCode = JSON.parse(printAtHome).assetCode;
    if (!currentAssetCode || currentAssetCode.includes('valentines')) {
      dispatch(setPahAssetCode('room-butterfly'));
    } else {
      dispatch(setPahAssetCode(currentAssetCode));
    }
  } else {
    localStorage.setItem(
      'printAtHome',
      JSON.stringify({ name: null, email: null, assetCode: null })
    );
  }

  // Load keys (state item: pahKeys)
  const pahKeys = await loadPahKeys();
  dispatch(populatePahKeys(pahKeys));
};
