import axios from 'axios';
import { track } from './mixpanel.service';

const getProduct = async (bookName: string) => {
  try {
    const { data } = await axios.get(`/keys/${bookName}/book-key.json`);
    // console.log('data:', data)
    return data;
  } catch (error: any) {
    track('[product.service] Error while getting book key =>', error);
    console.log(error.response, 'error??');
    return null;
  }
};

export { getProduct };
