import { ProductList } from '@/services/models';

const loadProductList = (payload: ProductList) => {
  return {
    type: 'LOAD_PRODUCT_LIST',
    payload
  };
};

export { loadProductList };
